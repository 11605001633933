@import 'variables';
@import 'mixins';

.favorite-theaters {
  .section-header {
    font-size: 30px !important;
    margin-bottom: 0;

    @media (min-width: $big-tablet-breakpoint) {
      font-size: 36px !important;
    }
  }

  .theater-showtimes-container {
    margin-top: 40px;
  }

  .favorite-theaters-error {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.32px;
    color: $dark-gray;
    margin-top: 24px;
    margin-bottom: 60px;

    @media (min-width: $big-tablet-breakpoint) {
      letter-spacing: 0.32px;
      margin-top: 40px;
      margin-bottom: 80px;
    }

    @media (min-width: $desktop-breakpoint) {
      margin-top: 50px;
      margin-bottom: 100px;
    }

    p:nth-child(2) {
      margin: 0;
    }
    
    .search-container {
      margin-top: 44px;

      @media (min-width: $tablet-breakpoint) {
        margin-top: 40px;
      }

      @media (min-width: $big-tablet-breakpoint) {
        margin-top: 50px;
      }

      .home-search {
        max-width: unset;

        .home-search-input-icon {
          display: unset;
          top: 7px;

          @media (min-width: $big-tablet-breakpoint) {
            display: none;
          }
        }

        .home-search-input-container {
          @media (min-width: $tablet-breakpoint) {
            max-width: 590px;
          }

          input {
            height: 40px;
            border-top: transparent;
            border-left: transparent;
            border-right: transparent;
            border-bottom: solid 2px rgba(221, 221, 221, 0.47);
            padding-left: 30px;

            @media (min-width: $big-tablet-breakpoint) {
              height: 56px;
              padding-left: 20px;
              border: solid 1px rgba(183, 183, 183, 0.47);
            }
          }
        }

        .find-showtimes-button-container {
          .find-showtimes {
            width: 57px;
            height: 40px;
            font-family: Roboto;
            font-size: 18px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0.9px;
            color: white;
            padding: 0;

            @media (min-width: $big-tablet-breakpoint) {
              width: 228px;
              height: 56px;
            }
          }
        }
      }
    }
  }
}
