@import 'variables';
@import 'mixins';

.editorial-module-banner-container {
  position: absolute;
  bottom: -18px;
  right: 0;
  pointer-events: none;
  overflow: hidden;
  width: 100%;
  height: 36px;

  @media (min-width: $tablet-breakpoint) {
    bottom: -17px;
    height: 34px;
  }

  .editorial-module-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(253deg, $black-absolute-color, $black-absolute-color 49%, $black-absolute-color);
    height: 100%;
    position: absolute;
    right: -12px;
    padding: 0 18px;
    transform: skewX(-35deg);
    color: $white;

    @media (min-width: $tablet-breakpoint) {
      right: -14px;
      padding: 0 $tablet-margin;
    }

    p {
      transform: skewX(35deg);
      color: $white-color;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.17px;
      text-transform: uppercase;
    }
  }
}

.editorial-module {
  @include page-container();
  position: relative;
  margin-bottom: 60px;

  @media (min-width: $tablet-breakpoint) {
    margin-bottom: 80px;
  }

  @media (min-width: $big-tablet-breakpoint) {
    margin-bottom: 60px;
    padding: 0;
  }

  .title {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.45px;
    color: $black;
    margin-top: 30px;

    @media (min-width: $tablet-breakpoint) {
      font-size: 30px;
      letter-spacing: 0.61px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      font-size: 22px;
      letter-spacing: 0.5px;
    }
  }

  .description {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: $black;
    margin-top: 10px;

    @media (min-width: $tablet-breakpoint) {
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: 0.29px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.25px;
    }
  }

  .call-to-action {
    display: flex;
    align-items: center;
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: 0.29px;
    color: $copper;
    cursor: pointer;

    svg {
      margin-left: 8px;
      height: 18px;
    }
  }

  .editorial-module-image-container {
    @include background-image-container();
    @include responsive-container(335, 188);
    overflow: visible !important;

    @media (min-width: $mobile-breakpoint) {
      @include non-responsive-container(335, 188);
    }

    @media (min-width: $tablet-breakpoint) {
      @include non-responsive-container(668, 286);
    }

    @media (min-width: $big-tablet-breakpoint) {
      @include non-responsive-container(304, 160);
    }

    @media (min-width: $desktop-breakpoint) {
      @include non-responsive-container(312, 170);
    }
  }
}

.spotlight-module {
  @include page-container();
  margin-top: 50px;
  margin-bottom: 80px;

  @media (min-width: $tablet-breakpoint) {
    padding: 0;
  }

  @media (min-width: $big-tablet-breakpoint) {
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .spotlight-header {
    font-family: Oswald;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: $black-absolute-color;
    text-transform: uppercase;
    margin-bottom: 8px;

    .spotlight-header-bold {
      font-weight: bold;
    }
  }

  .spotlight-subheader {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.29px;
    color: $purple;
    margin-bottom: 24px;

    @media (min-width: $big-tablet-breakpoint) {
      margin-bottom: 20px;
    }
  }

  .spotlight-movie-poster-container {
    @include background-image-container();
    @include responsive-container(335, 501);

    @media (min-width: $mobile-breakpoint) {
      @include non-responsive-container(335, 501);
    }

    @media (min-width: $tablet-breakpoint) {
      @include non-responsive-container(267, 400);
    }

    @media (min-width: $big-tablet-breakpoint) {
      @include non-responsive-container(304, 454);
    }

    @media (min-width: $desktop-breakpoint) {
      @include non-responsive-container(312, 466);
    }

    .spotlight-movie-poster-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(130, 130, 130, 0), rgba(0, 0, 0, 0.5));
    }

    .spotlight-movie-details {
      position: absolute;
      left: 24px;
      bottom: 36px;
      color: $white;

      .spotlight-movie-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 0.32px;
      }

      .spotlight-movie-metadata {
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: 0.25px;
      }
    }
  }

  // Tablet styling
  @media (min-width: $tablet-breakpoint) and (max-width: $big-tablet-breakpoint - 1) {
    position: relative;
    width: 100%;
    height: 400px;

    .spotlight-tablet-container {
      display: flex;
      justify-content: center;

      .spotlight-tablet-content-container {
        margin-top: 80px;
        margin-left: 35px;

        .spotlight-movie-details {
          color: $purple;

          .spotlight-movie-title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.32px;
            margin-bottom: 4px;
          }

          .spotlight-movie-metadata {
            font-size: 14px;
            letter-spacing: 0.25px;
          }
        }
      }

      .line {
        position: absolute;
        bottom: 131px;
        left: calc(50% + #{$tablet-margin});
        right: $tablet-margin;
        height: 1px;
        background-color: $black-absolute-color;
      }

      .gray-box {
        position: absolute;
        top: 30px;
        left: $tablet-margin;
        right: $tablet-margin;
        z-index: -1;
        height: 288px;
        background-color: $white-gray-color;
      }
    }
  }
}

.weekly-box-office-container {
  // 3/25 - Per Rick, removing this from the site while theaters are closed.
  // Delete the following `display: none;` line once theaters reopen.
  display: none;

  @include page-container();
  width: 100%;
  margin-bottom: 80px;

  @media (min-width: $tablet-breakpoint) {
    margin-bottom: 60px;
  }

  @media (min-width: $big-tablet-breakpoint) {
    padding: 0;
  }

  .section-header {
    letter-spacing: .54px;
  }

  .box-office-movie-container {
    position: relative;
    width: 100%;
    height: 94px;
    overflow: hidden;

    .box-office-movie-poster {
      @include background-image-container();
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      transform: scale(1.1);
      filter: blur(3px);

      .box-office-movie-poster-overlay {
        width: inherit;
        height: inherit;
        background-color: rgba(83, 83, 83, 0.43);
      }
    }

    .box-office-movie-detail {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 16px;

      .box-office-movie-rank {
        font-size: 44px;
        font-weight: bold;
        color: $white-color;
        opacity: 0.7;
        margin-right: 16px;
      }

      .box-office-movie-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
        letter-spacing: 0.29px;
        color: $white-color;

        a {
          color: $white-color;
        }
      }

      .box-office-movie-gross {
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: 0.29px;
        color: $white-color;
        opacity: 0.84;
      }
    }
  }

  .call-to-action {
    display: flex;
    align-items: center;
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: 0.29px;
    color: $copper;
    cursor: pointer;

    svg {
      margin-left: 8px;
      height: 18px;
    }
  }
}
