@import 'variables';

.ad-container {
  display: flex;
  margin: 0 auto;

  &.ad-centered {
    justify-content: center;
  }

  &.ad-top-margin {
    margin-top: 40px;
  }

  &.ad-bottom-margin {
    margin-bottom: 30px;
  }

  &.ad-end-margin {
    margin-bottom: 60px;

    @media screen and (min-width: $tablet-breakpoint) {
      margin-bottom: 80px;
    }
  }

  &.ad-navbar {
    height: 140px;
    padding: 25px 0;
  }

  &.ad-showtimes {
    margin-bottom: 1rem;
  }

  &.ad-box-office {
    margin: 1rem auto;
  }

  &.ad-theater-detail-top-right {
    margin-left: 20px;

    @media (max-width: $responsive-tablet) {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }

  &.ad-floating-bottom {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }

  &.ad-streaming-top {
    justify-content: center;

    @media screen and (min-width: $big-tablet-breakpoint) {
      justify-content: normal;
    }
  }
}
