@import 'variables';
@import 'mixins';

.streaming-grid-container {
  @include page-container();
  margin: 111px auto 80px;

  @media (min-width: $big-tablet-breakpoint) {
    margin: 176px auto 80px;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;

    @media (min-width: $big-tablet-breakpoint) {
      margin-bottom: 80px;
    }

    div {
      flex-shrink: 0;
    }

    header {
      font-family: Oswald;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.25;
      letter-spacing: 0.43px;
      color: $black-absolute-color;

      @media (min-width: $big-tablet-breakpoint) {
        font-size: 36px;
        line-height: normal;
        letter-spacing: 0.64px;
      }

      @media (min-width: $desktop-breakpoint) {
        font-size: 42px;
        letter-spacing: 0.75px;
      }
    }

    .filters-dropdowns {
      font-family: Roboto;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: 0.32px;
      color: $dark-gray;
      margin-top: 8px;

      .dropdown {
        margin: 0 30px;

        &.platform-dropdown {
          .menu.visible {
            display: grid !important;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 15px 20px;
            min-width: 448px;

            .item {
              padding: 0 !important;

              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: inline-block;
                vertical-align: top;
                margin: 7px 6px;
              }

              &:not(.selected) {
                opacity: 0.15;
              }

              svg {
                display: block;
              }

              img {
                width: 116px;
                max-height: 60px;
                border-radius: 0;
                margin: 0;
              }
            }

            .buttons-divider {
              grid-column: 1 / -1;
              margin: 25px 0 0;
            }

            .buttons-container {
              grid-column: 1 / -1;

              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                margin-top: 15px;
              }
            }
          }
        }

        &.genre-dropdown,
        &.rating-dropdown {
          .menu.visible {
            min-width: 319px;

            .item {
              display: flex;
              align-items: center;
              padding: 15px 10px !important;
              cursor: unset;

              &:first-child {
                padding: 0 10px 15px !important;
              }

              .checkbox {
                -webkit-appearance: none;
                width: 20px;
                height: 20px;
                border: solid 1px rgba(183, 183 , 183, 0.47);
                margin-right: 34px;
                cursor: pointer;

                &:checked::after {
                  content: "\2714";
                  display: flex;
                  justify-content: center;
                  color: $copper;
                }

                &:focus {
                  outline: none;
                }
              }
            }

            .divider {
              margin: 0;

              &.buttons-divider {
                margin-top: 15px;
              }
            }

            .buttons-container {
              padding-top: 15px;
            }
          }
        }

        &.genre-dropdown {
          .menu.visible {
            .genre-scroll-container {
              max-height: 460px;
              overflow: auto;
              margin-bottom: 30px;

              .divider {
                height: 0em;
                border-top: 1px solid rgba(34, 36, 38, 0.1);
              }
            }
          }
        }

        &.year-dropdown {
          .menu.visible {
            min-width: 501px;
            right: -140px !important;

            .year-range {
              font-family: Roboto;
              font-size: 24px;
              font-weight: normal;
              line-height: normal;
              letter-spacing: 0.43px;
              color: $dark-gray;
              text-align: center;
              margin-top: 20px;
              margin-bottom: 50px;
            }

            .year-slider {
              margin-bottom: 80px;
            }

            .buttons-divider {
              margin: 0;
            }

            .buttons-container {
              padding-top: 15px;
            }
          }
        }

        .chevron {
          padding-top: 2px;

          svg {
            margin-left: 8px;
          }
        }

        .filters-on {
          display: flex;
          align-items: center;

          svg {
            margin-left: 5px;
            margin-right: -3px;
          }
        }

        .dropdown-trigger {
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          outline: none;
          padding: 0;
          cursor: pointer;
        }

        .menu.visible {
          border: none;
          border-radius: unset;
          box-shadow: 0 2px 23px -10px rgba(0, 0, 0, 0.4);
          padding: 30px;
          margin-top: 14px;

          .item {
            font-family: Roboto;
            font-size: 18px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0.32px;
            color: $dark-gray;

            &:hover {
              background: unset;
            }
          }

          .buttons-divider {
            border-bottom: solid 1px $black-absolute-color;
          }

          .buttons-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
              font-family: Roboto;
              text-transform: uppercase;
              border: none;
              outline: none;
              padding: 0;
              cursor: pointer;

              &.clear-button {
                opacity: 0.3;
                font-size: 14px;
                font-weight: bold;
                line-height: normal;
                letter-spacing: 0.28px;
                color: $dark-gray;
                background-color: transparent;
              }

              &.apply-button {
                width: 132px;
                height: 50px;
                font-size: 18px;
                font-weight: normal;
                line-height: 1.33;
                letter-spacing: 0.9px;
                color: $white-color;
                background-image: linear-gradient(254deg, #f55f23, #e44129 49%, $copper);
              }
            }
          }
        }
      }
    }

    .modal-trigger {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      cursor: pointer;

      .filters-on {
        margin-right: 4px;
      }
    }
  }

  .movies-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px 11px;

    @media (min-width: $tablet-breakpoint) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px 16px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 50px 20px;
    }

    @media (min-width: $desktop-breakpoint) {
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 50px 24px;
    }

    .movie-link {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: inline-block;
        vertical-align: top;

        @media (min-width: $mobile-breakpoint) {
          width: 162px;
          margin: 15px 2px;
        }

        @media (min-width: $tablet-breakpoint) {
          width: 155px;
          margin: 15px 3px;
        }

        @media (min-width: $big-tablet-breakpoint) {
          width: 176px;
          margin: 25px 6px;
        }

        @media (min-width: $desktop-breakpoint) {
          width: 200px;
          margin: 25px 8px;
        }
      }

      .movie-poster {
        @include background-image-container();

        @media (max-width: $mobile-breakpoint - 1) {
          @include responsive-container(1, 1.5);
        }

        @media (min-width: $mobile-breakpoint) {
          width: 162px;
          height: 243px;
        }

        @media (min-width: $tablet-breakpoint) {
          width: 155px;
          height: 233px;
        }

        @media (min-width: $big-tablet-breakpoint) {
          width: 176px;
          height: 264px;
        }

        @media (min-width: $desktop-breakpoint) {
          width: 200px;
          height: 300px;
        }
      }

      .movie-title {
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.28px;
        color: $purplish-gray;
        text-align: center;
        margin-top: 12px;

        @media (min-width: $big-tablet-breakpoint) {
          font-size: 16px;
          letter-spacing: 0.29px;
        }
      }

      .movie-details {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0.25px;
        color: $dark-gray;
        text-align: center;
        margin-top: 4px;

        .movie-details-bullet {
          margin: 0 6px;
        }
      }
    }

    .middle-ad {
      grid-column: 1 / -1;
      margin: 20px 0;
    }
  }

  #streaming-grid-ad-bottom {
    margin-top: 50px;

    @media (min-width: $big-tablet-breakpoint) {
      margin-top: 100px;
    }
  }
}

.ui.dimmer {
  position: absolute;
  z-index: 10000;
  padding: 0;

  #filters-modal {
    width: 100%;
    height: 100%;
    border-radius: unset;
    padding: 30px 0;
    margin: 0;
    overflow: auto;

    .close-icon {
      position: absolute;
      top: 25px;
      right: 12px;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      cursor: pointer;
    }

    > .content {
      max-width: 375px;
      padding: 0 20px !important;
      margin: auto;

      .modal-header {
        font-family: Roboto;
        font-size: 24px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.43px;
        color: $dark-gray;
        text-align: center;
        margin-bottom: 50px;
      }

      .modal-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;

        .section-header {
          font-family: Roboto;
          font-size: 18px;
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0.32px;
          color: $dark-gray;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .section-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 15px 22px;
          width: 100%;

          button {
            border: none;
            outline: none;
            padding: 0;
            cursor: pointer;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              display: inline-block;
              width: 97px !important;
              height: 50px !important;
              vertical-align: top;
              margin: 7px;
            }

            &.platform-button {
              &:not(.selected) {
                opacity: 0.15;
              }

              svg, img {
                display: block;
                width: 100%;
                height: auto;
              }
            }

            &.genre-button,
            &.rating-button {
              width: 100%;
              height: 50px;
              font-family: Roboto;
              font-size: 12px;
              font-weight: normal;
              line-height: normal;
              letter-spacing: 0.21px;
              color: $white-color;
              background-color: $copper;
              text-transform: uppercase;
              padding: 5px;

              &:not(.selected) {
                opacity: 0.3;
              }
            }
          }
        }

        .show-more-less {
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0.25px;
          color: $copper;
          text-transform: uppercase;
          background-color: transparent;
          border: none;
          outline: none;
          padding: 0;
          margin-top: 30px;
          cursor: pointer;
        }

        .year-range {
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0.25px;
          color: $dark-gray;
          margin-top: -14px;
          margin-bottom: 13px;
        }
      }

      .buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          font-family: Roboto;
          text-transform: uppercase;
          border: none;
          outline: none;
          padding: 0;
          cursor: pointer;

          &.apply-button {
            width: 132px;
            height: 50px;
            font-size: 18px;
            font-weight: normal;
            line-height: 1.22;
            letter-spacing: 0.9px;
            color: $white-color;
            background-image: linear-gradient(254deg, #f55f23, #e44129 49%, $copper);
            margin-bottom: 20px;
          }

          &.clear-button {
            font-size: 14px;
            font-weight: bold;
            line-height: normal;
            letter-spacing: 0.25px;
            color: $dark-gray;
            background-color: transparent;
          }
        }
      }
    }
  }
}

// Custom styling for year slider
.streaming-grid-container .filters-dropdowns .year-dropdown,
#filters-modal .modal-section {
  .year-slider.rc-slider {
    .rc-slider-rail {
      height: 2px;
      background-color: #d8d8d8;
      border-radius: unset;

      @media (min-width: $big-tablet-breakpoint) {
        height: 3px;
      }
    }

    .rc-slider-track {
      height: 2px;
      background-color: $copper;

      @media (min-width: $big-tablet-breakpoint) {
        height: 4px;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: $copper;
        border-radius: 50%;
        margin-top: -5px;
        touch-action: pan-x;
        cursor: grab;

        @media (min-width: $big-tablet-breakpoint) {
          width: 18px;
          height: 18px;
          margin-top: -7px;
        }
      }

      &::before {
        left: -6px;

        @media (min-width: $big-tablet-breakpoint) {
          left: -9px;
        }
      }

      &::after {
        right: -6px;

        @media (min-width: $big-tablet-breakpoint) {
          right: -9px;
        }
      }
    }

    .rc-slider-step {
      z-index: -1;
    }
  }
}
