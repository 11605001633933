@import 'variables';
@import 'mixins';

.hero-banner {
  height: 236px;
  background: center;
  background-size: cover;
  color: white;
  margin-top: 100px;
  padding-top: 42px;
  margin-bottom: 26px;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: $tablet-breakpoint) {
    padding-top: 52px;
    margin-bottom: 27px;
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (min-width: $big-tablet-breakpoint) {
    height: 300px;
    padding-top: 75px;
    margin-bottom: 50px;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    padding-top: 78px;
  }

  .hero-banner-text-area {
    width: auto;
    margin: auto;

    @media screen and (min-width: $tablet-breakpoint) {
      width: 671px;
    }

    @media screen and (min-width: $big-tablet-breakpoint) {
      width: 947px;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      width: 1320px;
    }

    h1 {
      font-family: Oswald, serif;
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 30px;

      @media screen and (min-width: $big-tablet-breakpoint) {
        font-size: 36px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        font-size: 42px;
      }
    }

    p {
      font-family: Roboto, serif;
      opacity: 0.85;
      font-size: 14.5px;

      @media screen and (min-width: $tablet-breakpoint) {
        width: 497px;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        width: 445px;
        font-size: 16px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        width: 624px;
      }
    }
  }
}

.streaming-poster-row-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  align-items: center;

  // When browser width hits BigTablet,
  // no longer display containers stacked vertically:
  @media screen and (min-width: $big-tablet-breakpoint) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: normal;
    width: 960px;
    margin: 0 auto 100px auto;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    width: 1306px;
  }

  .whats-new-desktop,
  .leaving-soon-desktop {
    position: relative;
    height: 100%;

    @media screen and (min-width: $tablet-breakpoint) {
      width: 720px;
      padding: 0 26px;
    }

    @media screen and (min-width: $big-tablet-breakpoint) {
      width: 630px;
      padding: 0;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      width: 761px;
    }

    .section-header {
      margin-bottom: 16px;

      @media screen and (min-width: $big-tablet-breakpoint) {
        margin-bottom: 28px;
      }
    }

    .available-providers-container {
      margin-bottom: 3px;
    }

    .streaming-card-title {
      font-size: 20px;
      margin-bottom: 6px;

      @media screen and (min-width: $big-tablet-breakpoint) {
        font-size: 24px;
      }
    }

    .streaming-card-advisory-genre {
      margin-top: 0;
      margin-bottom: 12px;
    }

    // "Bottom Cards" are the cards displayed underneath
    // the top/"hero card" in a poster row:
    .bottom-cards {
      display: flex;
      justify-content: space-between;
      //temporary
      margin-top: 50px;

      .streaming-card {
        width: 48.5%;

        @media screen and (min-width: $tablet-breakpoint) {
          width: 326px;
          margin-bottom: 40px;
        }

        @media screen and (min-width: $big-tablet-breakpoint) {
          width: 304px;
        }

        @media screen and (min-width: $desktop-breakpoint) {
          width: 238px;
        }

        // Scoped to only the titles of the BOTTOM CARDS:
        .streaming-card-title {
          font-size: 20px;

          @media screen and (min-width: $big-tablet-breakpoint) {
            font-size: 24px;
          }
        }

        .availabilities-row {
          @media screen and (max-width: $tablet-breakpoint - 1) {
            width: 158px;
          }
        }

        .streaming-card-image-container {
          @include responsive-container(182, 102);
          @include background-image-container();

          @media screen and (min-width: $tablet-breakpoint) {
            width: 326px;
            height: 182px;
          }

          @media screen and (min-width: $big-tablet-breakpoint) {
            width: 304px;
            height: 170px;
          }

          @media screen and (min-width: $desktop-breakpoint) {
            width: 238px;
            height: 134px;
          }
        }
      }

      // On mobile, the second bottom card has
      // its padding removed so that it sits in the middle.
      // Please see 375px breakpoint Zeplins.
      .streaming-card:nth-child(2) {
        .streaming-card-details-container {
          padding: 0 !important;
        }
      }
    }
  }

  .whats-new-desktop {
    margin-bottom: 55px;

    @media screen and (min-width: $tablet-breakpoint) {
      margin-bottom: 53px;
    }

    @media screen and (min-width: $big-tablet-breakpoint) {
      margin-right: 24px;
      margin-top: 0;
      margin-bottom: 0;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      margin-bottom: 0;
      margin-right: 136px;
    }
  }

  .top-right-container-desktop {
    @media screen and (max-width: $tablet-breakpoint - 1) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    @media screen and (max-width: $tablet-breakpoint) {
      margin-bottom: 54px;
    }

    // Section header on top-right container only!
    .section-header {
      @media screen and (min-width: $big-tablet-breakpoint) {
        margin-bottom: 43px !important;
      }
    }
  }

  .leaving-soon-desktop {
    margin-bottom: 0;

    @media screen and (min-width: $big-tablet-breakpoint) {
      margin-left: 24px;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      margin-left: 136px;
    }
  }

  .popular-now-desktop,
  .top-picks-desktop {
    position: relative;
    margin-top: 72px;
    margin-bottom: 28px;
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (min-width: $tablet-breakpoint) {
      width: 668px;
      margin-top: 25px;
      max-height: 768px;
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (min-width: $big-tablet-breakpoint) {
      width: 304px;
      margin-top: 25px;
      max-height: none;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      width: 424px;
      margin-top: 35px;
    }

    .section-header {
      margin-bottom: 28px;
    }

    .small-poster-row {

      @media screen and (min-width: $tablet-breakpoint) {
        height: 250px;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        height: auto;
      }
    }

    .small-poster-row-bottom-cards {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (min-width: $tablet-breakpoint) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .small-streaming-card {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;
      height: 120px;

      @media screen and (max-width: $tablet-breakpoint - 1) {
        width: 100%;
      }

      // Cards are displayed differently on tablet only:
      @media screen and (min-width: $tablet-breakpoint) {
        display: inline-block;
        flex-direction: column;
        margin-right: 11px;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        display: flex;
        flex-direction: row;
        margin-bottom: 17px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        margin-bottom: 47px;
      }

      // Left and right align for mobile Small Poster Rows:
      &.left-aligned {
        @media screen and (max-width: $tablet-breakpoint - 1) {
          justify-content: flex-start;
        }

        .small-streaming-card-image-container {
          @media screen and (max-width: $tablet-breakpoint - 1) {
            margin-right: 11px;
          }
        }
      }

      &.right-aligned {

        @media screen and (max-width: $tablet-breakpoint - 1) {
          justify-content: flex-end;
        }

        .small-streaming-card-details-container {
          margin-right: 11px;

          @media screen and (max-width: $tablet-breakpoint - 1) {
            text-align: right;
          }

          .small-streaming-card-image-container {
            @media screen and (max-width: $tablet-breakpoint - 1) {
              margin-left: 3px;
            }
          }
        }
      }

      .small-streaming-card-details-container-wrapper {
        @media screen and (min-width: $big-tablet-breakpoint) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 105px;
        }

        @media screen and (min-width: $desktop-breakpoint) {
          height: 120px;
        }
      }

      // Overwrite style from cards.scss
      .small-streaming-card-details-container {
        max-width: 162px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (min-width: $tablet-breakpoint) {
          max-width: 212px;
        }

        @media screen and (min-width: $big-tablet-breakpoint) {
          width: 152px;
          display: flex;
          flex-direction: column;
          justify-content: normal;
        }

        @media screen and (min-width: $desktop-breakpoint) {
          width: 163px;
        }

        // Adjust font-sizes for movie-titles across breakpoints:
        .small-streaming-card-title {
          font-size: 18px;

          @media screen and (min-width: $tablet-breakpoint) {
            font-size: 20px;
          }

          @media screen and (min-width: $big-tablet-breakpoint) {
            width: 152px;
            font-size: 18px;
            line-height: 1.22;
            letter-spacing: 0.32px;
          }

          @media screen and (min-width: $desktop-breakpoint) {
            font-size: 22px;
            width: 191px;
            max-height: 56px;
            line-height: 1.27;
            letter-spacing: 0.39px;
          }
        }

        .availabilities-row {
          margin-top: 4px;
          margin-bottom: 0;

          @media screen and (min-width: $tablet-breakpoint) {
            margin-top: 12px;
            margin-bottom: 12px;
          }

          @media screen and (min-width: $big-tablet-breakpoint) {
            margin-top: 8px;
            margin-bottom: 8px;
          }

          @media screen and (min-width: $desktop-breakpoint) {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }

        .small-streaming-card-rating-genre {
          margin-top: 4px;

          @media screen and (min-width: $big-tablet-breakpoint) {
            margin-top: 0;
          }

          @media screen and (min-width: $desktop-breakpoint) {
            margin-top: 0;
          }
        }
      }



      // Set the fixed preview image sizes across breakpoints:
      .small-streaming-card-image-container {
        @include background-image-container();
        @include non-responsive-container(162, 102);

        @media screen and (min-width: $tablet-breakpoint) {
          width: 212px;
          height: 118px;
        }

        // Popular Now/Top Picks photos snap to
        // 4:3 aspect ratio on big tablet ONLY:
        @media screen and (min-width: $big-tablet-breakpoint) {
          margin-right: 11px;
          width: 140px;
          height: 105px;
        }

        @media screen and (min-width: $desktop-breakpoint) {
          width: 213px;
          height: 120px;
        }
      }
    }
  }

  .top-picks-desktop {
    margin-top: 66px;
    margin-bottom: 55px;

    @media screen and (min-width: $tablet-breakpoint) {
      margin-top: 67px;
      margin-bottom: 55px;
    }

    @media screen and (min-width: $big-tablet-breakpoint) {
      margin: 0;
    }

    // Cards in the second SPR must be spaced to fill out the whole column:
    .small-streaming-card {
      @media screen and (min-width: $big-tablet-breakpoint) {
        margin-bottom: 23px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        margin-bottom: 36px;
      }
    }
  }

  // Decrease bottom margin on mobile popular-now:
  .popular-now-desktop {
    @media screen and (max-width: $tablet-breakpoint) {
      margin-top: 52px;
      margin-bottom: 5px;
    }
  }

  // Apply styling to all Poster Row Section headers:
  .section-header {
    font-family: Oswald, serif;
    font-size: 30px;
    font-weight: 300;
    padding-left: 20px;

    // Apply left padding for mobile only:
    @media screen and (min-width: $tablet-breakpoint) {
      padding-left: 0;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      font-size: 36px;
    }

    .header-bold {
      font-weight: 700;
    }
  }
}

.availabilities-row {
  display: flex;
  height: 30px;
  margin-bottom: 12px;
  overflow: hidden;

  .streaming-tile {
    margin-right: 8px;

    &.prime-png {
      background-size: cover;
      width: 60px;
      height: 30px;

      &.small {
        width: 50px;
        height: 25px;
      }
    }
  }

  // Number of remaining tiles:
  p {
    display: flex;
    align-items: center;
    color: $gray;
    font-size: 12px;
  }
}

.top-container {

  @media screen and (min-width: $tablet-breakpoint) {
    margin-bottom: 55px;
  }

  @media screen and (min-width: $big-tablet-breakpoint) {
    height: 1108px;
    margin-bottom: 112px;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    height: 1070px;
    margin-bottom: 150px;
  }
}

.middle-container {
  margin-top: 51px;

  @media screen and (min-width: $tablet-breakpoint) {
    margin-top: 52px;
    margin-bottom: 0;
  }

  @media screen and (min-width: $big-tablet-breakpoint) {
    height: 1026px;
    margin-top: 119px;
    margin-bottom: 191px;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    height: 1035px;
    margin-top: 120px;
    margin-bottom: 151px;
  }

  .top-picks-desktop,
  .leaving-soon-desktop {
    @media screen and (min-width: $big-tablet-breakpoint) {
      height: 100%;
    }
  }
}

// Ad Containers:
// --------
.top-container {
  .ad-container {
    margin-bottom: 30px;

    @media screen and (min-width: $tablet-breakpoint) {
      margin-bottom: 50px;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      margin-bottom: 70px;
    }
  }
}

.ad-streaming-bottom {
  margin-bottom: 60px;

  @media screen and (min-width: $big-tablet-breakpoint) {
    margin-bottom: 80px;
  }
}

.call-to-action {
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
    height: 18px;
  }
}

.view-more-button {
  position: relative;
  font-family: Roboto, serif;
  font-weight: 500;
  font-size: 16px;
  color: $copper;

  @media screen and (min-width: $tablet-breakpoint) {
    margin-left: 0;
  }

  @media screen and (min-width: $big-tablet-breakpoint) {
    position: absolute;
    bottom: 0;
    top: 1078px;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    position: absolute;
    bottom: 0;
    top: 1045px;
  }

  .call-to-action {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: 0.29px;
    color: $copper;
    cursor: pointer;

    @media screen and (max-width: $tablet-breakpoint - 1) {
      max-width: 300px;
      margin-left: 20px;
    }
  }
}

.view-more-button-top {
  align-self: start;
  position: relative;
  font-family: Roboto, serif;
  font-weight: 500;
  font-size: 16px;
  color: $copper;

  @media screen and (min-width: $tablet-breakpoint) {
    margin-left: 0;
  }

  @media screen and (min-width: $big-tablet-breakpoint) {
    position: absolute;
    bottom: 0;
    top: 1080px;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    position: absolute;
    bottom: 0;
    top: 1080px;
  }

  .call-to-action {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: 0.29px;
    color: $copper;
    cursor: pointer;

    @media screen and (max-width: $tablet-breakpoint - 1) {
      max-width: 300px;
      margin-left: 20px;
    }
  }
}

//ie11 fix for padding under view more links on 1st and 2nd poster rows:
.view-more-button-top {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    top: 1120px !important;
  }
}

//ie11 fix for padding under view more links on 3rd and 4th poster rows:
.view-more-button {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    top: 1090px !important;
  }
}

// Add truncate to card descriptions::
.streaming-card-details-container {
  margin-top: 20px;

  .streaming-card-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;

    @media screen and (min-width: $tablet-breakpoint) {
      -webkit-line-clamp: 4;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      -webkit-line-clamp: 3;
    }
  }
}

// Flickity Styling:
// --------
.bottom-container {
  margin: 50px auto;

  @media screen and (min-width: $tablet-breakpoint) {
    width: 720px;
    margin: 53px auto;
  }

  @media screen and (min-width: $big-tablet-breakpoint) {
    width: 850px;
    margin: 116px auto 79px auto;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    width: 1200px;
    margin: 117px auto 83px auto;
  }

  .list-row-container {
    margin-bottom: 19px;

    @media screen and (min-width: $big-tablet-breakpoint) {
      margin-bottom: 42px;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      margin-bottom: 41px;
    }

    .flickity-slider {
      &:first-child {
        margin-left: 20px;

        @media screen and (min-width: $big-tablet-breakpoint) {
          margin-left: 0;
        }
      }
    }
  }

  .streaming-flickity-row-header {
    font-family: Roboto, serif;
    font-weight: 700;
    font-size: 22px;
    padding-left: 20px;
    margin-bottom: 11px;

    @media (min-width: $big-tablet-breakpoint) {
      font-size: 30px;
      margin-bottom: 13px;
      padding-left: 0;
    }
  }

  .poster-movie-cell-selector {
    text-align: center;
    width: 120px;
    margin-right: 20px;

    @media (min-width: $tablet-breakpoint) {
      @include flickity-cell-selector(5, 5px, 8px);
      width: 120px;
      margin-right: 7px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      @include flickity-cell-selector(4, 20px, 60px);
      width: 200px;
      margin: 0 7px;
    }

    @media (min-width: $desktop-breakpoint) {
      width: 200px;
      @include flickity-cell-selector(5, 24px, 80px);
    }

    .poster-container {
      margin: auto;
      width: 120px;
      height: 180px;
      @include background-image-container();
      background-color: $gray-color;

      @media screen and (min-width: $big-tablet-breakpoint) {
        width: 200px;
        height: 300px;
      }
    }

    .poster-title {
      font-family: Roboto, serif;
      font-weight: 700;
      font-size: 14px;
      color: $purplish-gray;
      margin-top: 12px;
      margin-bottom: 4px;

      @media (min-width: $tablet-breakpoint) {
        font-size: 14px;
      }
    }

    .poster-meta {
      font-family: Roboto, serif;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.25px;
      color: $gray;
    }
  }

  .flickity-prev-next-button {
    visibility: hidden;
    position: absolute;

    @media (min-width: $big-tablet-breakpoint) {
      visibility: visible;
    }

    .arrow {
      fill: #000000 !important;
    }

    &.previous {
      left: -66px;
      top: 147px;

      @media (min-width: $desktop-breakpoint) {
        left: -60px;
      }
    }

    &.next {
      right: -57px;
      top: 147px;

      @media (min-width: $desktop-breakpoint) {
        right: -60px;
      }
    }
  }

  .flickity-prev-next-button:disabled {
    .arrow {
      fill: #000000 !important;
      opacity: 0.2;
    }
  }
}

.streaming-poster-rating-genre-dot {
  font-size: 20px;
  vertical-align: middle;
}

