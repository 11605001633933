@import './variables';
@import './mixins';

// ListRowContainer uses Flickity for desktop browsers and
// the browser's native horizontal scroll for mobile devices
.list-row-container {
  // Cell Selector (Default)
  .default-cell-selector {
    @include flickity-cell-selector(3, 12.3px, $mobile-margin);

    @media (min-width: $tablet-breakpoint) {
      @include flickity-cell-selector(4, 16px, $tablet-margin);
    }

    @media (min-width: $big-tablet-breakpoint) {
      @include flickity-cell-selector(6, 17px, $big-tablet-margin);
    }

    @media (min-width: $desktop-breakpoint) {
      @include flickity-cell-selector(1, 1px, 2px, 120px);
    }

    .cell-container {
      @include responsive-container(120, 180);
      background-color: $gray-color;
    }
  }

  // Flickity Wrapper (Desktop Browsers)
  .flickity-wrapper {
    // Start positioning
    .flickity-slider {
      &:first-child {
        margin-left: $mobile-margin;

        @media (min-width: $tablet-breakpoint) {
          margin-left: $tablet-margin;
        }

        @media (min-width: $big-tablet-breakpoint) {
          margin-left: $big-tablet-margin;
        }

        @media (min-width: $desktop-breakpoint) {
          margin-left: $desktop-margin;
        }

        // +15px for scrollbar on chrome, firefox, and ie11
        @media (min-width: $desktop-breakpoint + 15px) {
          margin-left: calc(((100% - #{$desktop-breakpoint}) / 2) + #{$desktop-margin});
        }
      }
    }

    // Nav buttons
    .flickity-button,
    .flickity-button:hover {
      background: $white-color;
    }

    .flickity-prev-next-button {
      .flickity-button-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .arrow {
          fill: $red-color;
        }
      }

      &:disabled {
        opacity: 1;

        .flickity-button-icon {
          .arrow {
            fill: rgba(0, 0, 0, 0.2);
          }
        }
      }

      &.hidden {
        opacity: 0 !important;
      }

      &.previous {
        //left: 2px;

        @media (min-width: $big-tablet-breakpoint) {
          //left: 6px;
        }

        @media (min-width: $desktop-breakpoint) {
          //left: 30px;
        }

        // +15px for scrollbar on chrome, firefox, and ie11
        @media (min-width: $desktop-breakpoint + 15px) {
          //left: calc(((100% - #{$desktop-breakpoint}) / 2) + #{$desktop-margin} - 30px);
        }
      }

      &.next {
        //right: 2px;

        @media (min-width: $big-tablet-breakpoint) {
          //right: 6px;
        }

        @media (min-width: $desktop-breakpoint) {
          //right: 30px;
        }
      }
    }

    // Flickity IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      &.flickity-enabled.is-draggable .flickity-viewport {
        cursor: pointer;
      }
    }
  }

  // Scroll Wrapper (Mobile Devices)
  .scroll-wrapper {
    width: 100%;
    overflow-y: hidden;

    .scroll {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: -10px;
      padding-bottom: 10px;
      overflow-x: scroll;

      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      &::before {
        content: '';
        padding-left: $mobile-margin;

        @media (min-width: $tablet-breakpoint) {
          padding-left: $tablet-margin;
        }

        @media (min-width: $big-tablet-breakpoint) {
          padding-left: $big-tablet-margin;
        }

        @media (min-width: $desktop-breakpoint) {
          padding-left: $desktop-margin;
        }
      }

      &::after {
        content: '';
        padding-right: $mobile-margin;

        @media (min-width: $tablet-breakpoint) {
          padding-right: $tablet-margin;
        }

        @media (min-width: $big-tablet-breakpoint) {
          padding-right: $big-tablet-margin;
        }

        @media (min-width: $desktop-breakpoint) {
          padding-right: $desktop-margin;
        }
      }

      * {
        flex-shrink: 0;
      }
    }
  }
}


// Examples from grid proto-type:

// Video Experience Row
.video-experience-row-container {
  @include flickity-cell-centered-buttons(32px, 6px);
  margin-bottom: 24px;

  @media (min-width: $big-tablet-breakpoint) {
    @include flickity-cell-centered-buttons(40px, 4px);
  }

  @media (min-width: $desktop-breakpoint) {
    @include flickity-cell-centered-buttons(40px, 8px);
  }

  .video-cell-selector {
    @include flickity-cell-selector(2, 11px, $mobile-margin);

    @media (min-width: $tablet-breakpoint) {
      @include flickity-cell-selector(4, 16px, $tablet-margin);
    }

    @media (min-width: $big-tablet-breakpoint) {
      @include flickity-cell-selector(6, 24px, $big-tablet-margin);
    }

    @media (min-width: $desktop-breakpoint) {
      @include flickity-cell-selector(6, 24px, $desktop-margin, 200px);
    }

    .video-container {
      @include responsive-container(16, 9);
      @include background-image-container();
      border-radius: 4px;

      .thumbnail-container {
        @include fill-container();
        display: flex;
        justify-content: center;
        align-items: center;

        .play-icon {
          @include responsive-container(36, 36, (100% * (36/200)));

          .icon {
            @include fill-container();

            svg {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    .caption-container {
      height: 32px;
      margin-top: 6px;
      font-size: 12px;
      line-height: 1.33;
      color: $gray-dark-color;

      @media (min-width: $big-tablet-breakpoint) {
        height: 40px;
        margin-top: 8px;
        font-size: 14px;
        line-height: 1.43;
      }

      @media (min-width: $desktop-breakpoint) {
        margin-top: 4px;
      }
    }
  }
}

// Photo Experience Row
.photo-experience-row-container {
  margin-bottom: 24px;

  .photo-cell-selector {
    @include flickity-cell-selector(2, 11px, $mobile-margin);

    @media (min-width: $tablet-breakpoint) {
      @include flickity-cell-selector(4, 16px, $tablet-margin);
    }

    @media (min-width: $big-tablet-breakpoint) {
      @include flickity-cell-selector(6, 24px, $big-tablet-margin);
    }

    @media (min-width: $desktop-breakpoint) {
      @include flickity-cell-selector(6, 24px, $desktop-margin, 200px);
    }

    .photo-container {
      @include responsive-container(200, 150);
      @include background-image-container();
      border-radius: 4px;
    }
  }
}

// More Content Row
.more-content-row-container {
  .flickity-wrapper {
    margin-top: -10px;
  }

  .content-cell-selector {
    @include flickity-cell-selector(2, 11px, $mobile-margin);

    @media (min-width: $tablet-breakpoint) {
      @include flickity-cell-selector(3, 16px, $tablet-margin);
    }

    @media (min-width: $big-tablet-breakpoint) {
      @include flickity-cell-selector(3, 24px, $big-tablet-margin);
    }

    @media (min-width: $desktop-breakpoint) {
      @include flickity-cell-selector(3, 24px, $desktop-margin, 424px);
    }

    .content-container {
      @include responsive-container(162, 216);
      @include background-image-container();
      box-shadow: 0 2px 12px 0 rgba(181, 181, 181, 0.5);
      margin: 10px 0 12px;
      border-radius: 4px;

      @media (min-width: $tablet-breakpoint) {
        @include responsive-container(212, 280);
      }

      @media (min-width: $big-tablet-breakpoint) {
        @include responsive-container(304, 359);
        border-radius: 8px;
      }

      @media (min-width: $desktop-breakpoint) {
        @include responsive-container(424, 400);
      }
    }
  }
}
