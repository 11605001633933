@import 'variables';
@import 'mixins';

nav {
  button {
    padding: 0;

    :focus {
      outline: none;
    }
  }
}

.navbar-container,
.navbar-container-light {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  button {
    padding: 0;

    :focus {
      outline: none;
    }
  }

  .navbar {
    @include navbar-container();

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: $small-mobile-breakpoint) and (max-width: $mobile-breakpoint - 1)  {
      padding: 0 $small-mobile-margin;
    }

    @media (min-width: $mobile-breakpoint) {
      height: $header-height-mobile;
    }

    @media (min-width: $big-tablet-breakpoint) {
      height: $header-height-default;
    }

    .left-navigation-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .logo {
        margin: 7px 0 7px;

        @media (min-width: $tablet-breakpoint) {
          margin: 18px 0 19px;
        }

        @media (min-width: $big-tablet-breakpoint) {
          margin: 15px 0 18px;
        }

        div {
          display: flex;
        }
      }

      .left-navigation-links {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .in-theaters-route,
        .streaming-route,
        .showtimes-route,
        .trailers-route {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 16px;
          font-weight: 500;
          color: $white-color;
          position: relative;
          top: 5px;

          @media(min-width: $desktop-breakpoint) {
            margin-left: 45px;
          }
        }

        .in-theaters-route {
          width: max-content;
        }

        .in-theaters-route {
          @media(min-width: $big-tablet-breakpoint) {
            margin-left: 40px;
          }
        }

        .streaming-route,
        .showtimes-route,
        .trailers-route {
          @media(min-width: $big-tablet-breakpoint) {
            margin-left: 28px;
          }
        }
      }
    }

    .menu-container {
      width: 100%;

      .right-navigation-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .apps-icon-container {
          .apps-icon {
            .family-dropdown {
              font-family: 'Roboto';
              position: absolute;
              width: 308px;
              background-color: #fff;
              padding: 15px;
              font-size: 13px;
              box-shadow: 0 2px 23px -7px rgba(0, 0, 0, 0.36);
              z-index: 999999;
              display: block;

              @media(min-width: $big-tablet-breakpoint) {
                left: -138px;
                top: 79px;
              }

              &::after {
                content: '';
                top: -12px;
                right: 8.5rem;
                position: absolute;
                width: 0;
                height: 0;
                border-left: 31px solid transparent;
                border-right: 31px solid transparent;
                border-bottom: 28px solid #ffffff;
              }

              .family-heading {
                .text-center {
                  text-align: center;
                  margin-top: 15px;

                  .noovie-heading-text {
                    margin: 20px 0;
                    color: $purplish-gray;
                  }
                }
              }

              .family-item {
                transition: all ease 0.2s;
                padding: 10px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.18);
                display: block;

                &:last-child {
                  border-bottom: none;
                }

                .media {
                  display: flex;

                  .media-body {
                    .family-image {
                      display: flex;
                    }

                    .noovie-com-text,
                    .noovie-arcade-text,
                    .noovie-fantasy-text,
                    .noovie-trivia-text {
                      text-align: left;
                      color: $purplish-gray;
                      margin-top: 12px;
                      width: 90%;
                    }
                  }

                  .chevron-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }

    .menu-icon,
    .search-icon {
      background: transparent;
      border: transparent;
      display: flex;
      cursor: pointer;
    }

    .mobile-left-nav-container {
      display: flex;
      flex-direction: row;
      justify-content: left;

      .menu-icon {
        flex-direction: column;
        justify-content: center;
        margin-right: 8px;
      }

      .logo {
        a {
          div {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }

    .mobile-right-nav-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex: 1;

      @media (min-width: $small-mobile-breakpoint) {
        margin-left: 8px;
      }

      .search-icon {
        @media (min-width: $big-tablet-breakpoint) {
          position: relative;
          top: 5px;
        }
      }

      .apps-icon-container {
        .apps-icon {
          .family-dropdown {
            font-family: 'Roboto';
            position: absolute;
            width: 308px;
            background-color: #fff;
            padding: 15px;
            font-size: 13px;
            box-shadow: 0 2px 23px -7px rgba(0, 0, 0, 0.36);
            z-index: 999999;
            display: block;
            left: -199px;
            top: 69px;

            @media(min-width: $tablet-breakpoint) {
              left: -199px;
              top: 79px;
            }

            &::after {
              content: '';
              top: -7px;
              right: 59px;
              position: absolute;
              width: 0;
              height: 0;
              border-left: 31px solid transparent;
              border-right: 31px solid transparent;
              border-bottom: 28px solid #ffffff;
            }

            .family-heading {
              .text-center {
                text-align: center;
                margin-top: 15px;

                .noovie-heading-text {
                  margin: 20px 0;
                  color: $purplish-gray;
                }
              }
            }

            .family-item {
              transition: all ease 0.2s;
              padding: 10px 0;
              border-bottom: 1px solid rgba(0, 0, 0, 0.18);
              display: block;

              &:last-child {
                border-bottom: none;
              }

              .media {
                display: flex;

                .media-body {
                  .family-image {
                    display: flex;
                  }

                  .noovie-com-text,
                  .noovie-arcade-text,
                  .noovie-fantasy-text,
                  .noovie-trivia-text {
                    text-align: start;
                    color: $purplish-gray;
                    margin-top: 12px;
                    width: 90%;
                  }
                }

                .chevron-right {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }

    .search-button,
    .apps-icon-container,
    .account-icon {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: $white;
      cursor: pointer;
      background-color: transparent;
      border: transparent;

      @media(min-width: $tablet-breakpoint) {
        margin-left: 0;
      }

      @media (min-width: $big-tablet-breakpoint) {
        position: relative;
        top: 5px;
        margin-left: 32px;
      }

      svg {
        margin-right: 8px;
        align-self: baseline;

        @media (min-width: $small-mobile-breakpoint) {
          margin-right: 2px;
        }
      }

      .apps-icon {
        position: relative;
        width: 100%;
        height: 100%;

        svg {
          margin-right: 8px;
          align-self: baseline;
        }
      }
    }

    // Temporary modification from _navbar.scss
    .mobile-side-nav {
      z-index: 900;
      width: 220px;
      height: 382px;
      box-shadow: none !important;

      @media (min-width: $tablet-breakpoint) {
        width: 260px;
        height: 402px;
      }

      .header {
        @include page-container();
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        height: $header-height-mobile;

        @media (min-width: $tablet-breakpoint) {
          height: $header-height-default;
        }

        &::after {
          content: "";
          width: calc(100% / 3);
        }

        > .item {
          width: auto;
          margin: 0 auto;
          padding: 0;
          color: $white;
        }

        .menu-icon {
          text-align: left;
          display: inline-block;
          margin-right: 12px;
        }

        .header-text {
          text-align: center;
          display: inline-block;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -0.5px;
          text-transform: capitalize;

          @media (min-width: $tablet-breakpoint) {
            font-size: 28px;
          }
        }
      }

      .menu {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 20px;
        background-color: $white-color;
        height: 306px;
        overflow: auto;

        > .item:not(.noovie-logo) {
          width: 100%;
          text-align: left;
          padding: 14px 0;
          font-size: 20px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.36px;
          color: #5a595a;

          @media (min-width: $tablet-breakpoint) {
            font-size: 20px;
            padding: 23px 0;
          }
        }

        .noovie-logo {
          text-align: center;
          padding-top: 31px;
          padding-bottom: 14px;

          @media (min-width: $tablet-breakpoint) {
            padding-top: 35px;
            padding-bottom: 18px;
          }
        }
      }
    }
  }
}

.navbar-container {
  background-color: transparent;
}

.navbar-container-light {
  background-color: $white-color;

  .navbar {
    .left-navigation-container {
      .left-navigation-links {
        .in-theaters-route,
        .movies-route,
        .streaming-route,
        .showtimes-route,
        .trailers-route {
          color: $gray-dark-color;
        }
      }
    }

    .search-button,
    .apps-icon-container,
    .account-icon {
      color: $gray-dark-color;
    }
  }
}



