@import "variables";

.simple-date-selector {
  .date-tiles-container {
    @media screen and (min-width: $big-tablet-breakpoint) {
      align-items: center;
      display: flex;
    }

    &.scroll-wrapper {
      overflow-y: hidden;
      width: 100%;

      .disabled-scroll,
      .scroll {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        &::before {
          content: '';
          padding-left: $mobile-margin;

          @media (min-width: $tablet-breakpoint) {
            padding-left: $tablet-margin;
          }
        }

        &::after {
          content: '';
          padding-right: $mobile-margin;

          @media (min-width: $tablet-breakpoint) {
            padding-right: $tablet-margin;
          }
        }

        * {
          flex-shrink: 0;
        }
      }

      .disabled-scroll {
        overflow-x: hidden;
      }
    }

    .left-arrow {
      margin-right: 7px;
    }

    .date-tile {
      background-color: $white-gray-color;
      color: $gray-dark-color;

      @media screen and (min-width: $small-mobile-breakpoint) {
        height: 75px;
        margin-right: 6px;
        padding: 9px 23px 8px  23px;
        width: 75px;
      }

      @media screen and (min-width: $tablet-breakpoint) {
        height: 75px;
        margin-right: 6px;
        padding: 9px 29px 8px 29px;
        width: 87px;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        height: 109px;
        margin-right: 5px;
        padding: 20px 42px 20px 42px;
        width: 120px;
      }

      &.active {
        background-color: $copper;
        color: $white-color;

        @media screen and (min-width: $big-tablet-breakpoint) {
          height: 113px;
          bottom: 2px;
          padding: 25px 42px 20px 42px;
          position: relative;
        }
      }

      &:nth-of-type(8) {
        @media screen and (min-width: $big-tablet-breakpoint) {
          margin-right: 0;
        }
      }

      .date-container {
        text-align: center;

        .month {
          line-height: normal;

          @media screen and (min-width: $small-mobile-breakpoint) {
            font-size: 12px;
            height: 14px;
            letter-spacing: 0.21px;
          }

          @media screen and (min-width: $big-tablet-breakpoint) {
            font-size: 14px;
            height: 16px;
            letter-spacing: 0.25px;
          }
        }

        .day {
          font-weight: 500;
          line-height: normal;

          @media screen and (min-width: $small-mobile-breakpoint) {
            font-size: 24px;
            height: 28px;
            letter-spacing: 0.43px;
          }

          @media screen and (min-width: $big-tablet-breakpoint) {
            font-size: 30px;
            height: 35px;
            letter-spacing: 0.54px;
          }
        }

        .day-of-week {
          font-size: 12px;
          height: 14px;
          letter-spacing: 0.21px;
          line-height: normal;
        }
      }
    }

    .right-arrow {
      margin-left: 7px;
    }
  }
}