@import 'variables';
@import 'mixins';

.showtimes-container {
  @include page-container();

  .theater-showtimes-container {
    margin-bottom: 80px;

    .theater-container {
      box-shadow: 0 2px 55px 0 rgba(0, 0, 0, 0.06);
      margin-bottom: 16px;

      .chevron {
        cursor: pointer;
        height: 36px;
        margin-left: 32px;
        transform: rotate(0deg);
        transition: transform 0.3s linear;
      }

      &.closed {
        .chevron {
          transform: rotate(180deg);
          transition: transform 0.3s linear;
        }
      }

      .theater-accordion-container.closed + .theater-movies-container {
        display: none;
      }

      .theater-accordion-container {
        display: flex;
        justify-content: space-between;
        padding: 25px 24px 24px;

        @media (min-width: $big-tablet-breakpoint) {
          align-items: center;
          padding: 48px;
        }

        @media (min-width: $desktop-breakpoint) {
          padding: 50px 44px 40px 50px;
        }

        .theater-accordion {
          .theater-name-container {
            display: flex;
            align-items: flex-start;
            margin-bottom: 6px;

            @media (min-width: $tablet-breakpoint) {
              align-items: center;
            }

            @media (min-width: $big-tablet-breakpoint) {
              margin-bottom: 3px;
            }
  
            .theater-name {
              font-family: Roboto;
              font-size: 18px;
              font-weight: bold;
              line-height: normal;
              letter-spacing: 0.32px;
              color: $dark-gray;
              margin: 0;

              @media (min-width: $big-tablet-breakpoint) {
                font-size: 24px;
                letter-spacing: 0.43px;
              }
            }
  
            .favorite-theater-toggle {
              display: flex;
              align-items: center;
              margin-left: 8px;
              margin-right: 8px;
              cursor: pointer;
            }
          }
  
          .theater-address-container {
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0.25px;
            color: $dark-gray;
  
            @media (min-width: $big-tablet-breakpoint) {
              display: flex;
              align-items: center;
              font-size: 16px;
              letter-spacing: 0.29px;
            }

            div {
              display: flex;
              align-items: center;
            }

            .theater-address {
              margin-bottom: 10px;

              @media (min-width: $big-tablet-breakpoint) {
                margin-bottom: 0;
              }
            }

            .theater-distance-container {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.25px;
              color: $copper;
              cursor: pointer;

              @media (min-width: $big-tablet-breakpoint) {
                font-size: 16px;
                letter-spacing: 0.29px;
              }

              .map-marker {
                margin-right: 6px;
              }
            }
  
            .view-amenities {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.25px;
              color: $dark-gray;
              cursor: pointer;

              @media (min-width: $big-tablet-breakpoint) {
                font-size: 16px;
                letter-spacing: 0.29px;
              }
            }
          }

          .no-ticketing-message {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.25px;
            color: rgba(0, 0, 0, 0.5);
            margin-top: 24px;

            @media (min-width: $tablet-breakpoint) {
              font-size: 16px;
              letter-spacing: 0.29px;
              margin-top: 21px;
            }
          }
        }
      }
  
      .theater-movies-container {
        position: relative;
        padding: 30px 24px 24px;

        @media (min-width: $big-tablet-breakpoint) {
          padding: 50px 48px 48px;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 24px;
          right: 24px;
          height: 1px;
          background: $dark-gray;

          @media (min-width: $big-tablet-breakpoint) {
            left: 48px;
            right: 48px;
          }

          @media (min-width: $desktop-breakpoint) {
            left: 50px;
            right: 50px;
          }
        }

        .dot {
          margin-left: 8px;
          margin-right: 8px;
        }

        .no-showtimes-available-container {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 0.25px;
          color: $dark-gray;
          padding-top: 30px;
          padding-bottom: 36px;

          @media (min-width: $big-tablet-breakpoint) {
            font-size: 18px;
            letter-spacing: 0.32px;
            padding-bottom: 32px;
          }
        }

        .theater-movies-row {
          @media (min-width: $tablet-breakpoint) {
            display: flex;
          }

          &:not(:last-child) {
            margin-bottom: 60px;

            @media (min-width: $big-tablet-breakpoint) {
              margin-bottom: 100px;
            }
          }

          .theater-movie-poster {
            width: auto;
            height: 114px;
            margin-right: 20px;

            @media (min-width: $small-mobile-breakpoint) and (max-width: $tablet-breakpoint - 1) {
              margin-right: 12px;
            }

            @media (min-width: $tablet-breakpoint) {
              width: auto;
              height: 150px;
            }

            @media (min-width: $big-tablet-breakpoint) {
              width: auto;
              height: 180px;
            }

            @media (min-width: $desktop-breakpoint) {
              width: auto;
              height: 182px;;
            }
          }
  
          .theater-movies-nested-container {
            @media (min-width: $small-mobile-breakpoint) and (max-width: $tablet-breakpoint - 1) {
              margin-bottom: 23px;
            }

            @media (max-width: $tablet-breakpoint - 1) {
              display: flex;
            }

            .theater-movie-detail-container {
              @media (min-width: $tablet-breakpoint) {
                margin-bottom: 13px;
              }
    
              @media (min-width: $big-tablet-breakpoint) {
                margin-bottom: 28px;
              }

              @media (min-width: $desktop-breakpoint) {
                margin-bottom: 40px;
              }
    
              .video-player {
                svg {
                  @media (min-width: $tablet-breakpoint) {
                    width: 20px;
                    height: 20px;
                  }
    
                  @media (min-width: $big-tablet-breakpoint) {
                    width: unset;
                    height: unset;
                  }
                }
              }

              .theater-metadata-container {
                .theater-movie-title {
                  font-family: Oswald;
                  font-size: 20px;
                  font-weight: bold;
                  height: 41px;
                  line-height: normal;
                  letter-spacing: 0.36px;
                  color: $dark-gray;
                  margin-bottom: 5px;
  
                  @media (min-width: $big-tablet-breakpoint) {
                    font-size: 30px;
                    letter-spacing: 0.54px;
                    margin-bottom: 3px;
                  }
                }

                .theater-metadata {
                  font-family: Roboto;
                  font-size: 14px;
                  font-weight: normal;
                  line-height: 1.43;
                  letter-spacing: 0.25px;
                  color: $dark-gray;

                  @media (min-width: $tablet-breakpoint) {
                    font-size: 16px;
                    line-height: 1.38;
                    letter-spacing: 0.29px;
                  }
                }
              }
            }
          }

          .movie-showtime-container {
            &:not(:last-child) {
              margin-bottom: 30px;

              @media (min-width: $big-tablet-breakpoint) {
                margin-bottom: 44px;
              }
            }

            .showtime-detail-container {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              font-family: Roboto;
              font-size: 16px;
              font-weight: 500;
              line-height: 1.88;
              letter-spacing: 0.29px;
              color: $dark-gray;

              @media (min-width: $tablet-breakpoint) {
                margin-bottom: -6px;
              }

              @media (min-width: $big-tablet-breakpoint) {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0.32px;
                margin-bottom: 2px;
              }

              @media (min-width: $desktop-breakpoint) {
                margin-bottom: 10px;
              }

              .showtime-amenity-container {
                display: flex;
                align-items: center;
              }
            }

            .showtime-row {
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              .showtime-container {
                width: 92px;
                height: 41px;
                border: solid 0.5px $dark-gray;
                margin-top: 20px;
                margin-right: 10px;
                cursor: pointer;

                @media (min-width: $big-tablet-breakpoint) {
                  margin-top: 10px;
                  margin-right: 10px;
                }

                .unavailable-showtime,
                .available-showtime {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                }

                .unavailable-showtime {
                  font-family: Roboto;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: 0.29px;
                  color: $dark-gray;
                }

                .available-showtime {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: 0.29px;
                  color: $copper;

                  &:hover {
                    color: white;
                    background-color: $copper;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ad-showtimes {
      margin-bottom: 12px;
  
      @media (min-width: $tablet-breakpoint) {
        margin-bottom: 16px;
      }

      @media (min-width: $big-tablet-breakpoint) {
        margin-top: 24px;
        margin-bottom: 24px;
      }
    }
  }
}
