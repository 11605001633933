@import 'variables';
@import 'mixins';

.app-container {
  position: relative;
  overflow: hidden;
}

.page-container {
  @include page-container();
}

button {
  border: 0;
  background: transparent;
  outline: 0;

  :focus, :active {
    outline: 0;
  }
}