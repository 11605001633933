@import "./variables";

.ui.basic.modal.transition.visible.active.mobile-search-modal {
  top: 0;
  margin-top: 21px;

  svg {
    float: right;
  }

  .content {
    margin-top: 21px;

    .search-bar-input {
      max-width: 600px;
      width: 100%;
      height: 30px;
      position: relative;
      top: 5px;
      border-bottom: 1px solid $white;

      input {
        background-color: transparent;
        color: $white;
        border: none;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.29px;
        height: 100%;

        &::placeholder {
          font-size: 16px;
          letter-spacing: 0.29px;
          color: rgba(255, 255, 255, 0.5);
        }
      }

      .search-icon {
        width: auto;
      }
    }
  }
}

.ui.basic.modal.transition.visible.active.mobile-search-modal-light {
  top: 0;
  margin-top: 21px;

  svg {
    float: right;
  }

  .content {
    margin-top: 21px;

    .search-bar-input {
      max-width: 600px;
      width: 100%;
      height: 30px;
      position: relative;
      top: 5px;
      border-bottom: 1px solid $white;

      input {
        background-color: transparent;
        color: $black;
        border: none;
        font-size: 15px;
        font-weight: normal;
        letter-spacing: -0.3px;
        height: 100%;

        &::placeholder {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: normal;
          color: #bfbfbf;
        }
      }

      .search-icon {
        width: auto;
      }
    }
  }
}