@import 'variables';
@import 'mixins';

.spotlight-container-component-holder {
  background: $black-absolute-color;
  font-weight: bold;
  width: 100%;
  min-height: 415px;

  @media (min-width: $mobile-breakpoint) {
    min-height: 459px;
  }

  @media (min-width: $tablet-breakpoint) {
    min-height: initial; // turn off min-height
    height: 263px;
  }

  @media (min-width: $big-tablet-breakpoint) {
    height: 278px;
  }

  @media (min-width: $desktop-breakpoint) {
    height: 330px;
  }

  .spotlight-container {
    position: relative;
    padding: 40px 20px 0 20px;
    color: $white-color;
    margin: auto;

    @media screen and (min-width: $small-mobile-breakpoint) {
      height: 100%;
      padding-bottom: 40px;
    }

    @media screen and (min-width: $tablet-breakpoint) {
      width: 720px;
      height: 183px;
      padding: 40px 26px 0 26px;
    }

    @media screen and (min-width: $big-tablet-breakpoint) {
      padding: 40px 32px 0 32px;
      width: 1024px;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      width: 1188px;
      padding: 0;
    }

    .spotlight-header {
      display: inline-block;
      font-size: 18px;
      height: 24px;
      letter-spacing: 0.32px;
      line-height: 24px;
      margin-bottom: 8px;
      width: 235px;

      @media screen and (min-width: $small-mobile-breakpoint) {
        height: 21px;
        line-height: normal;
      }

      @media screen and (min-width: $tablet-breakpoint) {
        height: 21px;
        line-height: normal;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        height: 24px;
        line-height: 24px;
      }
    }

    .spotlight-movie-title {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: $white-color;
      display: -webkit-box;
      font-size: 30px;
      overflow: hidden;

      @media screen and (min-width: $small-mobile-breakpoint) {
        max-height: 72px;
        letter-spacing: .54px;
        line-height: 36px;
        margin-bottom: 4px;
        width: 335px;
      }

      @media screen and (min-width: $tablet-breakpoint) {
        max-height: 100px;
        letter-spacing: .75px;
        line-height: 50px;
        width: 326px;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        margin-bottom: 8px;
        width: 362px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        font-size: 42px;
        width: 440px;
      }
    }

    .spotlight-movie-info,
    .mobile-call-to-action-text,
    .tablet-call-to-action-text {
      color: $white-color;
      margin: 0;
      font-size: 16px;
      letter-spacing: 0.29px;
      line-height: 22px;
    }

    .mobile-call-to-action-text {
      display: inline-flex;

      @media screen and (min-width: $small-mobile-breakpoint) {
        height: 22px;
        width: 198px;
      }
    }

    .spotlight-photo,
    .mobile-spotlight-photo {
      @include background-image-container();
      height: 100%;

      @media screen and (max-width: $tablet-breakpoint - 1) {
        position: absolute;
        width: 100%;
      }
    }

    // On mobile, spotlight content is displayed vertically.
    // The tablet containers are for displaying content horizontally:
    .tablet-container,
    .big-tablet-container {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    .big-tablet-container {
      position: absolute;
      width: 959px;
      top: -17px;
      justify-content: normal;

      @media screen and (min-width: $desktop-breakpoint) {
        width: 100%;
      }

      &.right-aligned {
        justify-content: space-between;

        @media screen and (min-width: $desktop-breakpoint) {
          padding-left: 47px;
        }

        .spotlight-content-left {
          padding-left: 10px;

          @media screen and (min-width: $big-tablet-breakpoint) {
            padding-left: 0;
          }
        }
      }

      .spotlight-photo-holder-big-tablet {
        @media screen and (min-width: $desktop-breakpoint) {
          right: 126px;
        }

        &.left-aligned {
          @media screen and (min-width: $big-tablet-breakpoint) {
            margin-right: 32px;
          }

          @media screen and (min-width: $desktop-breakpoint) {
            margin-right: 55px;
          }
        }

        .spotlight-photo-oversized {
          @include background-image-container();
        }
      }

      .spotlight-content-left {
        @media screen and (min-width: $big-tablet-breakpoint) {
          padding-top: 38px;
        }

        @media screen and (min-width: $desktop-breakpoint) {
          padding-top: 62px;
        }
      }
    }

    .spotlight-content-left {
      position: relative;

      @media screen and (min-width: $big-tablet-breakpoint) {
        height: 314px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        height: 366px;
      }

      .tablet-call-to-action-text {
        position: absolute;
        bottom: 62px;

        @media screen and (min-width: $tablet-breakpoint) {
          bottom: 1px;
        }

        @media screen and (min-width: $big-tablet-breakpoint) {
          bottom: 38px;
        }

        @media screen and (min-width: $desktop-breakpoint) {
          bottom: 62px;
        }
      }
    }

    .spotlight-photo-holder-tablet {
      float: right;

      @media (max-width: $tablet-breakpoint - 1) {
        float: none;
        margin: 38px auto 40px auto;
        padding-bottom: 56%;
        position: relative;
        width: 100%;
      }

      @media (min-width: $tablet-breakpoint) {
        height: 182.5px;
        width: 326px;
      }

      @media (max-width: $tablet-breakpoint - 1) {
        margin-top: 38px;
        margin-bottom: 40px;
      }
    }

    .spotlight-photo-oversized {
      width: 562px;
      height: 314px;
      background: $gray;

      // Thumbnail is even larger on 1440 breakpoint:
      @media screen and (min-width: $desktop-breakpoint) {
        width: 652px;
        height: 366px;
      }
    }
  }
}

.spotlight-overlay-items {
  position: relative;
  height: 100%;


  .spotlight-opacity-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $black-absolute-color;
    opacity: 0.2;
    text-align: center;
    z-index: 9;
  }

  .play-icon-container {
    position: absolute;
    text-align: center;
    z-index: 10;
    width: 143px;
    height: 96px;
    left: 50%;
    top: 54%;
    margin: -42px 0 0 -57px;

    @media (min-width: $small-mobile-breakpoint) {
      margin-top: -38px;
      width: 112px;
    }

    @media (min-width: $tablet-breakpoint) {
      margin-top: -39px;
      width: 112px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      margin: -62px 0 0 -71px;
      width: 143px;
    }

    .watch-trailer {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      height: 21px;
      letter-spacing: .32px;
      line-height: normal;
      margin-top: 11px;
      white-space: nowrap;

      @media (min-width: $small-mobile-breakpoint) {
        height: 16px;
        letter-spacing: .25px;
        margin-top: 4px;
        width: 113px;
      }

      @media (min-width: $tablet-breakpoint) {
        margin-top: 4px;
        width: 113px;
      }

      @media (min-width: $big-tablet-breakpoint) {
        font-size: 18px;
        margin-top: 11px;
        width: 144px;
      }
    }
  }

  .play-icon-container:hover {
    cursor: pointer;
  }
}