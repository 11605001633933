@import "variables";
@import "mixins";

.footer-container {
  display: flex;
  flex-direction: column;
  background: $charcoal-color;
  color: $white-color;
  font-family: Roboto;
  padding: 50px 0;

  @media (min-width: $tablet-breakpoint) {
    padding: 76px 0;
  }

  a {
    color: $white-color;
  }

  .icon-container, .link-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 24px 50px;

    @media (min-width: $tablet-breakpoint) {
      margin-bottom: 40px;
    }
  }

  .social-links-container {
    @include page-container();
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .icon-container {
      width: 27px;
      height: 27px;

      svg {
        display: flex;
      }
    }
  }

  .info-links-container {
    @include page-container();
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: $tablet-breakpoint) {
      flex-direction: row;
    }

    .link-container {
      width: 80px;
      height: auto;
    }

    a {
      font-size: 18px;
      letter-spacing: 0.32px;
    }
  }

  .footnote-container {
    @include page-container();
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    @media (min-width: $tablet-breakpoint) {
      flex-direction: row;
    }

    .footnote-links-container {
      margin: 0;

      @media (min-width: $tablet-breakpoint) {
        margin-right: 34px;
      }

      .dot-separator {
        margin: 0 14px;
      }
    }

    .copyright-container {
      color: rgba(255, 255, 255, 0.5);
      line-height: 1;
      text-align: center;
      margin: 50px 0 0;

      .all-rights::before {
        content: "\A";
        white-space: pre;
      }

      @media (min-width: $tablet-breakpoint) {
        margin: 0;

        .all-rights::before {
          white-space: normal;
        }
      }
    }
  }

  .footer-ccpa {
    clear: both;
    text-align: center;

    @media (min-width: $tablet-breakpoint) {
      clear: none;
      text-align: left;
      display: inline;
    }

    .dot-separator {
      display: none;

      @media (min-width: $tablet-breakpoint) {
        display: inline;
      }
    }
  }
}
