@import './variables';

.search-bar {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-left: 15px;

  @media (min-width: $big-tablet-breakpoint) {
    position: relative;
    bottom: 5px;
  }

  svg {
    opacity: 1;

    @media (min-width: $big-tablet-breakpoint) {
      position: relative;
      top: 10px;
    }

    @media (max-width: $big-tablet-breakpoint - 1) {
      width: 3em;
    }
  }

  .default-search-bar-container {
    display: flex;
    width: 100%;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 65%;
    }

    .search-bar-input {
      background: transparent;
      width: 100%;
      height: 30px;
      position: relative;
      top: 5px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);
      margin-right: 15px;

      @media (min-width: $big-tablet-breakpoint) {
        height: 40px;
      }

      input {
        background-color: transparent;
        color: $white;
        border: none;
        height: 100%;
        padding-right: 0 !important;
        padding-bottom: 0;

        @media (max-width: $big-tablet-breakpoint - 1) {
          font-size: 16px;
          letter-spacing: 0.29px;
          padding-left: 5px !important;
          padding-bottom: 5px;
        }

        &::placeholder {
          font-size: 16px;
          letter-spacing: 0.29px;
          color: rgba(255, 255, 255, 0.5);
        }
      }

      button {
        background-color: $white;
        border: none;

        @media (max-width: $big-tablet-breakpoint - 1) {
          padding: 0.5em;
        }
      }
    }
  }
}

.search-bar-light {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-left: 15px;

  @media (min-width: $big-tablet-breakpoint) {
    position: relative;
    bottom: 5px;
  }

  svg {
    opacity: 1;

    @media (min-width: $big-tablet-breakpoint) {
      position: relative;
      top: 10px;
    }

    @media (max-width: $big-tablet-breakpoint - 1) {
      width: 3em;
    }
  }

  .default-search-bar-container {
    display: flex;
    width: 100%;

    .search-bar-input {
      background: $white;
      width: 100%;
      height: 30px;
      position: relative;
      top: 5px;
      border-bottom: 1px solid $gray;
      margin-right: 15px;

      @media (min-width: $big-tablet-breakpoint) {
        height: 40px;
      }

      input {
        background-color: $white-color;
        color: $gray;
        border: none;
        height: 100%;
        padding-right: 0 !important;
        padding-bottom: 0;

        @media (max-width: $big-tablet-breakpoint - 1) {
          font-size: 15px;
          font-weight: bold;
          letter-spacing: -0.3px;
          padding-left: 5px !important;
          padding-bottom: 5px;
        }

        &::placeholder {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: normal;
          color: #bfbfbf;
        }
      }

      button {
        background-color: $white;
        border: none;

        @media (max-width: $big-tablet-breakpoint - 1) {
          padding: 0.5em;
        }
      }
    }
  }
}
