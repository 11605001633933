@import "./variables";

.sign-in-register-container {
  margin-left: 32px;

  @media(min-width: $big-tablet-breakpoint) {
    position: relative;
  }

  @media(max-width: $big-tablet-breakpoint - 1) {
    margin-left: 0;
  }

  .outer-dropdown-container {
    background: transparent;
    border: transparent;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    > div {
      display: flex;
    }

    .user-account-default-container {
      @media(min-width: $big-tablet-breakpoint) {
        position: relative;
        top: 5px;
      }

      .account-signed-in-icon {
        margin-right: 7px;
      }

      .username-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media(max-width: $desktop-breakpoint - 1) {
          max-width: 170px;
        }

        .username {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.38;
          letter-spacing: 0.29px;
          color: $white-color;
        }
      }
    }
  }

  div {
    .user-account-dropdown {
      position: absolute;
      background-color: $white;
      width: 174px;
      top: 6rem;
      box-shadow: 0 2px 23px -7px rgba(0, 0, 0, 0.36);
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 9999;

      @media(min-width: $big-tablet-breakpoint) {
        left: -55px;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: -13px;
        right: 5rem;
        border-left: 31px solid transparent;
        border-right: 31px solid transparent;
        border-bottom: 28px solid #ffffff;
      }

      .user-account-settings,
      .user-account-log-out {
        text-align: left;
        border: transparent;
        background: transparent;
        padding: 20px;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.56;
        letter-spacing: 0.32px;
        color: $purplish-gray;
        cursor: pointer;

        a {
          color: $purplish-gray;
        }
      }
    }

    .user-account-modal-dropdown {
      z-index: 9999;
      position: absolute;
      background-color: $white;
      width: 182px;
      top: 0;
      right: 0;
      box-shadow: 0 2px 23px -7px rgba(0, 0, 0, 0.36);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $purplish-gray;

      @media(max-width: $tablet-breakpoint - 1) {
        width: 172px;
      }

      .user-account-name-logo {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 0 25px;
        border-bottom: 1px solid $purplish-gray;

        @media(max-width: $tablet-breakpoint - 1) {
          height: $header-height-mobile;
        }

        @media(min-width: $tablet-breakpoint) {
          height: $header-height-default;
        }

        .username-container {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .username {
            max-width: 98px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.25px;

            @media(max-width: $tablet-breakpoint - 1) {
              max-width: 90px;
            }
          }
        }

        .account-signed-in-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .user-account-settings,
      .user-account-log-out {
        background: transparent;
        border: transparent;
        text-align: left;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.36px;
        padding: 29px 25px;
        cursor: pointer;
      }
    }
  }
}

.sign-in-register-container-light {
  margin-left: 32px;

  @media(min-width: $big-tablet-breakpoint) {
    position: relative;
  }

  @media(max-width: $big-tablet-breakpoint - 1) {
    margin-left: 0;
  }

  .outer-dropdown-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    > div {
      display: flex;
    }

    .user-account-default-container {
      @media(min-width: $big-tablet-breakpoint) {
        position: relative;
        top: 5px;
      }

      .account-signed-in-icon {
        margin-right: 7px;
      }

      .username-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media(max-width: $desktop-breakpoint - 1) {
          max-width: 170px;
        }

        .username {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.38;
          letter-spacing: 0.29px;
          color: $purplish-gray;
        }
      }
    }
  }

  div {
    .user-account-dropdown {
      position: absolute;
      background-color: $white;
      width: 174px;
      top: 6rem;
      box-shadow: 0 2px 23px -7px rgba(0, 0, 0, 0.36);
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 9999;

      @media(min-width: $big-tablet-breakpoint) {
        left: -55px;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: -13px;
        right: 5rem;
        border-left: 31px solid transparent;
        border-right: 31px solid transparent;
        border-bottom: 28px solid #ffffff;
      }

      .user-account-settings,
      .user-account-log-out {
        padding: 20px;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.56;
        letter-spacing: 0.32px;
        color: $purplish-gray;
        cursor: pointer;
      }
    }

    .user-account-modal-dropdown {
      z-index: 9999;
      position: absolute;
      background-color: $white;
      width: 182px;
      top: 0;
      right: 0;
      box-shadow: 0 2px 23px -7px rgba(0, 0, 0, 0.36);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $purplish-gray;

      @media(max-width: $tablet-breakpoint - 1) {
        width: 172px;
      }

      .user-account-name-logo {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 0 25px;
        border-bottom: 1px solid $purplish-gray;

        @media(max-width: $tablet-breakpoint - 1) {
          height: $header-height-mobile;
        }

        @media(min-width: $tablet-breakpoint) {
          height: $header-height-default;
        }

        .username-container {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .username {
            max-width: 98px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.25px;

            @media(max-width: $tablet-breakpoint - 1) {
              max-width: 90px;
            }
          }
        }

        .account-signed-in-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .user-account-settings,
      .user-account-log-out {
        background: transparent;
        border: transparent;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.36px;
        padding: 29px 25px;
        cursor: pointer;
      }
    }
  }
}
