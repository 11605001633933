@import 'variables';
@import 'mixins';

.trailer-tray-container {
  margin-top: 0;
  margin-bottom: 60px;
  width: 100%;

  @media (min-width: $big-tablet-breakpoint) {
    margin-top: 14px; // offset bottom of .medium-card
    margin-bottom: 90px;
  }

  .trailer-tray {
    position: relative;
    width: 100%;
    height: 506px;
    color: $white-color;
    background: $black-absolute-color;
    padding: 50px 0;
    overflow: hidden;

    @media (min-width: $tablet-breakpoint) {
      height: 524px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      height: 516px;
      padding: 56px 0;
    }

    @media (min-width: $desktop-breakpoint) {
      height: 558px;
    }

    .trailer-tray-header {
      display: inline-flex;
      width: 100%;
      margin-bottom: 60px;
      margin-left: 10px;

      @media (min-width: $mobile-breakpoint) {
        margin-left: calc(#{$mobile-margin-auto} + #{$mobile-margin});
      }
        @media (min-width: $tablet-breakpoint) {
        margin-left: calc(#{$tablet-margin-auto} + #{$tablet-margin});
      }

      @media (min-width: $big-tablet-breakpoint) {
        margin-bottom: 50px;
        margin-left: calc(#{$big-tablet-margin-auto} + 80px);
      }

      @media (min-width: $desktop-breakpoint) {
        margin-left: calc(#{$desktop-margin-auto} + 132px);
      }

      h1 {
        letter-spacing: 0.75px;
        line-height: 40px;
        font-family: Oswald, serif;
        font-size: 35px;
        font-weight: 300;
        margin: 0;

        .hot-bold {
          font-weight: 700;
        }
      }

      .trailer-tray-divider {
        display: flex;
        flex: 1;
        align-self: center;
        height: 1px;
        margin-left: 20px;
        background: #979797;
      }
    }

    @include flickity-cell-centered-buttons(144px, 24px);

    @media (min-width: $tablet-breakpoint) {
      @include flickity-cell-centered-buttons(122px, 24px);
    }

    @media (min-width: $big-tablet-breakpoint) {
      @include flickity-cell-centered-buttons(122px, 36px);
    }

    @media (min-width: $desktop-breakpoint) {
      @include flickity-cell-centered-buttons(102px, 36px);
    }

    .hot-trailer-cell-selector {
      margin: 0 10px;

      @media (min-width: $big-tablet-breakpoint) {
        margin: 0 12px;
      }

      .trailer-container {
        @include background-image-container();
        position: relative;
        width: 246px;
        height: 138px;
        overflow: hidden;

        @media (min-width: $tablet-breakpoint) {
          width: 298px;
          height: 168px;
        }

        @media (min-width: $big-tablet-breakpoint) {
          width: 272px;
          height: 156px;
        }

        @media (min-width: $desktop-breakpoint) {
          width: 375px;
          height: 218px;
        }

        .thumbnail-container {
          @include fill-container();
          display: flex;
          justify-content: center;
          align-items: center;

          .play-icon {
            @include responsive-container(36, 36, (100% * (36 /246)));

            @media (max-width: $tablet-breakpoint) {
              margin-left: 0!important;
              overflow: visible;
            }

            @media (min-width: $tablet-breakpoint) {
              @include responsive-container(48, 48, (100% * (48/298)));
            }

            @media (min-width: $big-tablet-breakpoint) {
              @include responsive-container(48, 48, (100% * (48/272)));
            }

            @media (min-width: $desktop-breakpoint) {
              @include responsive-container(60, 60, (100% * (60/375)));
            }

            .icon {
              @include fill-container();

              @media (max-width: $tablet-breakpoint) {
                margin-left: 0!important;
                overflow: visible;
              }

              button {
                margin-left: 0;
                border: 0;
                background: transparent;
                padding: 0;

                svg {
                  margin-left: 0;
                }
              }

              svg {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }

      .trailer-context-container {
        width: 246px;
        height: 144px;
        margin-top: 24px;

        @media (min-width: $tablet-breakpoint) {
          width: 298px;
          height: auto;
        }

        @media (min-width: $big-tablet-breakpoint) {
          width: 272px;
          margin-top: 36px;
        }

        @media (min-width: $desktop-breakpoint) {
          width: 376px;
        }

        h1 {
          font-family: Oswald, serif;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 12px;
        }

        p {
          font-family: Roboto, serif;
          font-size: 14px;
        }
      }
    }

    // Flickity Wrapper (Desktop Browsers)
    .flickity-wrapper {
      .hot-trailer-cell-selector {
        // Dim all off-screen cells:
        @media (min-width: $tablet-breakpoint) {
          opacity: 0.4;
        }

        &.is-selected {
          // Brighten all on-screen cells:
          opacity: 1;
        }
      }

      .flickity-slider {
        &:first-child {
          margin-left: calc(#{$mobile-margin-auto} + #{$mobile-margin});

          @media (min-width: $tablet-breakpoint) {
            margin-left: calc(#{$tablet-margin-auto} + #{$tablet-margin});
          }

          @media (min-width: $big-tablet-breakpoint) {
            margin-left: calc(#{$big-tablet-margin-auto} + 80px);
          }

          @media (min-width: $desktop-breakpoint) {
            margin-left: calc(#{$desktop-margin-auto} + 132px);
          }
        }
      }

      .flickity-button,
      .flickity-button:hover {
        background: transparent;
      }

      .flickity-prev-next-button {
        height: 42px;
        width: 42px;

        .flickity-button-icon {
          .arrow {
            fill: $white-color;
          }
        }

        &:disabled {
          opacity: 1;

          .flickity-button-icon {
            .arrow {
              fill: rgba(255, 255, 255, 0.3);
            }
          }
        }

        &.previous {
          display: none;

          @media (min-width: $big-tablet-breakpoint) {
            display: block;
            left: calc(#{$big-tablet-margin-auto} + 18px);
          }

          @media (min-width: $desktop-breakpoint) {
            left: calc(#{$desktop-margin-auto} + 56px);
          }
        }

        &.next {
          display: none;

          @media (min-width: $big-tablet-breakpoint) {
            display: block;
            right: calc(#{$big-tablet-margin-auto} + 18px);
          }

          @media (min-width: $desktop-breakpoint) {
            right: calc(#{$desktop-margin-auto} + 56px);
          }
        }
      }
    }

    // Scroll Wrapper (Mobile Devices)
    .scroll-wrapper {
      .scroll {
        &::before {
          content: '';
          padding-left: calc(#{$mobile-margin-auto} + #{$mobile-margin} - 10px);

          @media (min-width: $tablet-breakpoint) {
            padding-left: calc(#{$tablet-margin-auto} + #{$tablet-margin} - 12px);
          }

          @media (min-width: $big-tablet-breakpoint) {
            padding-left: calc(#{$big-tablet-margin-auto} + 80px - 12px);
          }

          @media (min-width: $desktop-breakpoint) {
            padding-left: calc(#{$desktop-margin-auto} + 132px - 12px);
          }
        }

        &::after {
          content: '';
          padding-right: calc(#{$mobile-margin-auto} + #{$mobile-margin} - 10px);

          @media (min-width: $tablet-breakpoint) {
            padding-right: calc(#{$tablet-margin-auto} + #{$tablet-margin} - 12px);
          }

          @media (min-width: $big-tablet-breakpoint) {
            padding-right: calc(#{$big-tablet-margin-auto} + 80px - 12px);
          }

          @media (min-width: $desktop-breakpoint) {
            padding-right: calc(#{$desktop-margin-auto} + 132px - 12px);
          }
        }
      }
    }
  }
}
