@import './variables';
@import './mixins';

.poster-rows-container {
  width: 100%;

  @media (min-width: $big-tablet-breakpoint) {
    max-width: 808px;
  }

  .poster-row-tabs,
  .poster-row-movies {
    // Flickity Wrapper
    .flickity-wrapper {
      // Start positioning
      .flickity-slider {
        &:first-child {
          margin-left: $mobile-margin;

          @media (min-width: $tablet-breakpoint) {
            margin-left: $tablet-margin;
          }

          @media (min-width: $big-tablet-breakpoint) {
            margin-left: 0;
          }
        }
      }

      // Nav buttons
      .flickity-button,
      .flickity-button:hover {
        background-color: transparent;
      }

      .flickity-prev-next-button {
        .flickity-button-icon {
          .arrow {
            fill: rgba(0, 0, 0, 1);
          }
        }

        &:disabled {
          opacity: 1;

          .flickity-button-icon {
            .arrow {
              fill: rgba(0, 0, 0, 0.15);
            }
          }
        }
      }
    }
  }

  .poster-row-tabs {
    margin: 33px 0;

    @media (min-width: $big-tablet-breakpoint) {
      margin-top: 53px;
    }

    // Flickity Cell Selector
    .poster-tab-cell-selector {
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }

      @media (min-width: $tablet-breakpoint) {
        margin-right: 48px;
      }

      @media (min-width: $big-tablet-breakpoint) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: 0;
      }

      .poster-tab {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        cursor: pointer;
        padding: 5px 0;
        border-bottom: 2px solid transparent;

        &.selected-tab {
          border-bottom: 2px solid $purplish-gray;
        }

        &.labeled-tab {
          padding-top: 0;
        }

        .tab-label {
          font-family: Roboto, sans-serif;
          font-size: 12px;
          letter-spacing: 0.21px;
          line-height: normal;
          color: $purplish-gray;
          margin: 0 0 2px;
        }

        .tab-name {
          font-family: Roboto, sans-serif;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.25px;
          line-height: normal;
          color: $purplish-gray;
          white-space: nowrap;
          margin: 0;

          @media (min-width: $big-tablet-breakpoint) {
            font-size: 18px;
            letter-spacing: 0.32px;
          }
        }
      }
    }

    // Flickity Wrapper
    .flickity-wrapper {
      // Nav buttons
      .flickity-prev-next-button {
        width: 20px;
        height: 20px;

        &.previous {
          display: none;

          @media (min-width: $big-tablet-breakpoint) {
            display: block;
            left: -40px;
          }
        }

        &.next {
          display: none;

          @media (min-width: $big-tablet-breakpoint) {
            display: block;
            right: -40px;
          }
        }
      }
    }
  }

  .poster-row-movies {
    // Flickity Cell Selector
    .poster-movie-cell-selector {
      @include flickity-cell-selector(1, 10px, $mobile-margin, 120px);

      @media (min-width: $tablet-breakpoint) {
        @include flickity-cell-selector(4, 17px, $tablet-margin, 120px);
      }

      @media (min-width: $big-tablet-breakpoint) {
        @include flickity-cell-selector(5, 17px, $big-tablet-margin, 120px);
      }

      @media (min-width: $desktop-breakpoint) {
        @include flickity-cell-selector(5, 17px, $desktop-margin, 120px);
      }

      .poster-container {
        @include responsive-container(120, 180);
        @include background-image-container();
      }

      .poster-title,
      .poster-meta {
        font-family: Roboto, sans-serif;
        color: $purplish-gray;
        line-height: normal;
        text-align: center;
        margin: 0;
      }

      .poster-title {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.28px;
        margin-top: 12px;
      }

      .poster-meta {
        font-size: 14px;
        letter-spacing: 0.25px;
        margin-top: 4px;

        @media (min-width: $big-tablet-breakpoint) {
          font-size: 12px;
          letter-spacing: 0.21px;
        }
      }
    }

    // Flickity Wrapper
    .flickity-wrapper {
      // Nav buttons
      @include flickity-cell-content-centered-buttons(120, 180);

      .flickity-prev-next-button {
        width: 42px;
        height: 42px;

        &.previous {
          display: none;

          @media (min-width: $big-tablet-breakpoint) {
            display: block;
            left: -50px;
          }
        }

        &.next {
          display: none;

          @media (min-width: $big-tablet-breakpoint) {
            display: block;
            right: -50px;
          }
        }
      }
    }
  }
}
