@import 'variables';

.ui.modal.amenities-modal {
  svg {
    float: right;
    position: relative;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
  }

  .header {
    text-align: center;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: -0.5px;
    font-size: 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .amenities-meta-header {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.3px;
      color: #a3a3a3;
    }
  }

  .scrolling.content {
    padding: 0 !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @media (max-width: $tablet-breakpoint - 1) {
      max-height: calc(100% - 74px);
    }

    @media (max-width: $big-tablet-breakpoint - 1) {
      max-height: calc(100% - 88px);
    }

    .description {
      .amenities-container {
        .amenity-information-container {
          padding: 1.5rem;
          font-style: normal;
          font-stretch: normal;

          .amenity-name {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.3px;
            color: #000000;
          }

          .amenity-description {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.43;
            letter-spacing: normal;
          }
        }

        .amenity-information-container:nth-child(even) {
          background-image: linear-gradient(to right, $off-white, $white);
        }
      }
    }
  }
}

.amenities-modal-open {
  overflow: hidden;
}

@media (max-width: $big-tablet-breakpoint - 1) {
  .main-wrapper {
    .ui.page.modals.dimmer.transition {
      height: calc(100% - 60px);
      padding: 0;
      top: inherit !important;
      bottom: 0;
    }
  }

  .ui.inverted.dimmer > .ui.modal.amenities-modal {
    margin: 0;
    box-shadow: none;
  }

  .ui.modal.amenities-modal {
    width: 100%;
    height: 100%;
    border: solid 1px $gray-color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
