@import 'mixins';
@import 'variables';
@import 'mixins';

.movie-card {
  width: 100%;

  @media (min-width: $tablet-breakpoint) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 60px;
  }

  @media (min-width: $big-tablet-breakpoint) {
    flex-direction: column;
    width: 304px;
  }

  @media (min-width: $desktop-breakpoint) {
    width: 424px;
  }

  @media (min-width: $tablet-breakpoint) and (max-width: $big-tablet-breakpoint - 1) {
    > *:first-child {
      margin-right: 16px;
    }
  }

  .movie-card-image-container {
    @include background-image-container();
    width: 182px;
    height: 116px;
    margin-bottom: 0;

    @media (min-width: $tablet-breakpoint) {
      width: 326px;
      height: 208px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      width: 100%;
      height: 170px;
      margin-bottom: 20px;
    }

    @media (min-width: $desktop-breakpoint) {
      height: 238px;
    }
  }

  .movie-card-details-container {
    display: flex;
    flex-direction: column;
    width: 162px;

    @media (min-width: $tablet-breakpoint) {
      width: 326px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      width: 304px;
    }

    @media (min-width: $desktop-breakpoint) {
      width: 424px;
    }

    .movie-card-title {
      font-family: Oswald;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.33;
      // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
      // letter-spacing: 0.38px;
      color: black;
      margin-bottom: 4px;

      @media (min-width: $tablet-breakpoint) {
        font-size: 24px;
        line-height: 1.25;
        // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
        // letter-spacing: 0.5px;
        margin-bottom: 8px;
      }

      @media (min-width: $big-tablet-breakpoint) {
        // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
        // letter-spacing: 0.39px;
      }
    }

    .movie-card-meta {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0.25px;
      color: $purplish-gray;
      margin-bottom: 12px;

      @media (min-width: $tablet-breakpoint) {
        line-height: 1.14;
      }
    }
  }

  .movie-card-description-container {
    @media (max-width: $tablet-breakpoint - 1) {
      margin: 24px 20px 60px 20px;
    }
  }

  .movie-card-description {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.38;
    // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
    // letter-spacing: 0.29px;
    color: $purplish-gray;
  }

  // Mobile
  .movie-card-image-and-details-container {
    display: flex;

    > *:first-child {
      margin-right: 11px;
    }

    .left-aligned {
      margin-left: 20px;
    }
  }
}
