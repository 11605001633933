@import 'variables';

.section-header {
  font-family: Oswald, serif;
  font-size: 30px !important;
  font-weight: 300;
  color: #000000 !important;

  // Apply left padding for mobile only:
  @media screen and (min-width: $tablet-breakpoint) {
    padding-left: 0;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    font-size: 36px;
  }

  .header-bold {
    font-weight: 700;
  }
}
