@import 'variables';
@import 'mixins';

.in-theaters {
  .page-title-container,
  .poster-rows-list-container {
    @media screen and (min-width: $small-mobile-breakpoint) {
      padding-left: $small-mobile-margin;
      padding-right: $small-mobile-margin;
    }

    @media screen and (min-width: $mobile-breakpoint) {
      padding-left: $mobile-margin;
      padding-right: $mobile-margin;
    }

    @media screen and (min-width: $tablet-breakpoint) {
      padding-left: $big-tablet-margin;
      padding-right: $big-tablet-margin;
    }

    @media screen and (min-width: $big-tablet-breakpoint) {
      padding-left: $big-tablet-margin;
      padding-right: $big-tablet-margin;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      margin: auto;
      max-width: 1440px;
      padding-left: $desktop-margin;
      padding-right: $desktop-margin;
    }
  }

  .page-title-wrapper {
    background-color: $black-absolute-color;

    .page-title-container {
      width: 100%;

      @media screen and (min-width: $small-mobile-breakpoint) {
        height: 102px;
        padding-top: 50px;
        padding-bottom: 20px;
      }

      @media screen and (min-width: $mobile-breakpoint) {
        height: 148px;
        padding-top: 86px;
        padding-bottom: 20px;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        height: 222px;
        padding-top: 128px;
        padding-bottom: 32px;
      }

      .page-title {
        color: $white-color;
        font-family: Oswald, serif;
        font-weight: bold;
        line-height: normal;

        @media screen and (min-width: $small-mobile-breakpoint) {
          font-size: 24px;
          height: 32px;
          letter-spacing: 0.43px;
        }

        @media screen and (min-width: $big-tablet-breakpoint) {
          font-size: 42px;
          height: 62px;
          letter-spacing: 0.75px;
        }
      }
    }
  }

  .poster-rows-list-wrapper {
    background-color: $black-absolute-color;

    .poster-rows-list-container {
      @media screen and (min-width: $small-mobile-breakpoint) {
        min-height: 450px;
      }

      @media screen and (min-width: $mobile-breakpoint) {
        min-height: 404px;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        min-height: 550px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        min-height: 504px;
      }
    }
  }

  .movie-news-wrapper {
    width: 100%;

    @media screen and (min-width: $desktop-breakpoint) {
      max-width: 1440px;
      margin: auto;
    }

    .movie-news-container {
      @media screen and (min-width: $tablet-breakpoint) {
        margin: auto;
        width: 668px;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        margin-right: 24px;
        width: 632px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        margin-right: 136px;
        width: 760px;
      }

      .section-header {
        @media screen and (min-width: $small-mobile-breakpoint) and (max-width: $mobile-breakpoint - 1) {
          margin-bottom: 24px;
          padding-left: $small-mobile-margin;
        }

        @media screen and (min-width: $mobile-breakpoint) and (max-width: $tablet-breakpoint - 1) {
          height: 40px;
          letter-spacing: 0.54px;
          line-height: 40px;
          margin-bottom: 24px;
          padding-left: 20px;
          width: 335px;
        }

        @media screen and (min-width: $tablet-breakpoint) {
          font-size: 30px !important;
          height: 40px;
          letter-spacing: 0.54px;
          line-height: 40px;
          margin-bottom: 24px;
        }

        @media screen and (min-width: $big-tablet-breakpoint) {
          font-size: 36px !important;
          letter-spacing: 0.64px;
          line-height: 46px;
          margin-bottom: 40px;
        }

        .header-bold {
          @media screen and (min-width: $mobile-breakpoint) {
            height: 40px;
            width: 335px;
          }

          @media screen and (min-width: $big-tablet-breakpoint) {
            height: 46px;
          }
        }
      }

      .large-card {
        @media screen and (min-width: $tablet-breakpoint) {
          margin-bottom: 60px;
        }

        @media screen and (min-width: $big-tablet-breakpoint) {
          margin-bottom: 50px;
          width: 100%;
        }

        @media screen and (min-width: $desktop-breakpoint) {
          width: 100%;
        }

        .large-card-image-container {
          width: 100%;

          .card-label-container {
            @media screen and (min-width: $mobile-breakpoint) and (max-width: $big-tablet-breakpoint - 1) {
              height: 24px;
              top: 10px;
            }

            .card-label {
              @media screen and (min-width: $tablet-breakpoint) and (max-width: $big-tablet-breakpoint - 1) {
                letter-spacing: 1.56px;
                line-height: normal;
              }
            }
          }
        }

        .large-card-details-container {
          @media screen and (min-width: $small-mobile-breakpoint) and (max-width: $mobile-breakpoint - 1) {
            padding-left: $small-mobile-margin;
            padding-right: $small-mobile-margin;
          }

          .large-card-title {
            @media screen and (min-width: $tablet-breakpoint) {
              font-size: 36px;
              letter-spacing: 0.75px;
              line-height: 46px;
              margin-bottom: 26px;
              width: 668px;
            }

            @media screen and (min-width: $big-tablet-breakpoint) {
              font-size: 24px;
              height: 30px;
              letter-spacing: 0.39px;
              line-height: 30px;
              margin-bottom: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 632px;
            }

            @media screen and (min-width: $desktop-breakpoint) {
              width: 760px;
            }
          }

          .large-card-description {
            color: $dark-gray;
            line-height: 22px;

            @media screen and (min-width: $small-mobile-breakpoint) {
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
            }

            @media screen and (min-width: $tablet-breakpoint) {
              width: 668px;
            }

            @media screen and (min-width: $big-tablet-breakpoint) {
              width: 632px;
            }

            @media screen and (min-width: $desktop-breakpoint) {
              width: 760px;
            }
          }
        }
      }

      .medium-card {
        margin-top: 60px;

        .medium-card-container {
          .medium-card-image-container {
            @media screen and (min-width: $mobile-breakpoint) {
              width: 48.5%;

              &.left-aligned {
                margin-right: 3%;
              }
            }

            @media screen and (min-width: $tablet-breakpoint) {
              width: 326px;
            }

            .medium-card-image {
              @media screen and (min-width: $mobile-breakpoint) {
                padding-top: 116px;
                width: 100%;
              }

              @media screen and (min-width: $tablet-breakpoint) {
                padding-top: 208px;
                width: 326px;
              }

              .card-label-container {
                height: 24px;
                top: 10px;

                .card-label {
                  height: 22px;
                  letter-spacing: 1.56px;
                  line-height: 22px;
                }
              }
            }
          }

          .medium-card-details-container {
            &.right-aligned {
              @media screen and (min-width: $small-mobile-breakpoint) and (max-width: $mobile-breakpoint - 1) {
                padding-left: $small-mobile-margin;
              }
            }

            @media screen and (min-width: $tablet-breakpoint) {
              height: 202px;
              overflow: hidden;
              position: relative;
            }

            .medium-card-published-date {
              @media screen and (min-width: $mobile-breakpoint) {
                margin-bottom: 4px;
              }

              @media screen and (min-width: $tablet-breakpoint) {
                margin-bottom: 6px;
              }
            }

            .medium-card-name {
              @media screen and (min-width: $mobile-breakpoint) {
                line-height: 24px;
                width: 161px;
              }

              @media screen and (min-width: $tablet-breakpoint) {
                line-height: 30px;
                width: 326px;
              }
            }

            .medium-card-description {
              line-height: 22px;
            }
          }
        }

        .medium-card-mobile-description-container {
          padding-top: 24px;
          padding-bottom: 0;
          width: 100%;

          @media screen and (min-width: $small-mobile-breakpoint) and (max-width: $mobile-breakpoint - 1) {
            padding-left: $small-mobile-margin;
            padding-right: $small-mobile-margin;
          }

          @media screen and (min-width: $tablet-breakpoint) and (max-width: $big-tablet-breakpoint - 1) {
            padding-top: 0;
          }

          .medium-card-mobile-description {
            line-height: 22px;

            @media screen and (min-width: $small-mobile-breakpoint) and (max-width: $tablet-breakpoint - 1) {
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .small-card-container {
        @media screen and (min-width: $big-tablet-breakpoint) {
          margin-bottom: 0;
        }

        .small-card {
          @media screen and (min-width: $big-tablet-breakpoint) {
            margin-right: 24px;
          }
          @media screen and (max-width: $desktop-breakpoint) {
            margin-right: 24px;
          }
          .small-card-image-container {
            @media screen and (min-width: $big-tablet-breakpoint) {
              padding-top: 170px;
              width: 304px;
            }

            @media screen and (min-width: $desktop-breakpoint) {
              padding-top: 134px;
              width: 238px;
            }

            .card-label-container {
              height: 24px;

              @media screen and (min-width: $big-tablet-breakpoint) {
                top: 15px;
              }

              @media screen and (min-width: $desktop-breakpoint) {
                top: 10px;
              }

              .card-label {
                font-weight: bold;
                height: 22px;
                letter-spacing: 1.56px;
                line-height: 22px;
                width: unset;
              }
            }
          }

          .small-card-details-container {
            .small-card-published-date {
              height: 16px;
              width: 116px;
            }

            .small-card-title {
              @media screen and (min-width: $big-tablet-breakpoint) {
                width: 304px;
              }

              @media screen and (min-width: $desktop-breakpoint) {
                width: 237px;
              }
            }

            .small-card-description {
              color: $dark-gray;
              line-height: 22px;

              @media screen and (min-width: $big-tablet-breakpoint) {
                width: 304px;
              }

              @media screen and (min-width: $desktop-breakpoint) {
                width: 238px;
              }
            }
          }
        }
      }
    }
  }

  .spotlight-container-component-holder {
    margin-top: 60px;
    @media (min-width: $big-tablet-breakpoint) {
      margin-top: 0;
    }
  }

  .movie-card-container-component-holder {
    width: 375px;
    margin: 60px auto;

    @media (min-width: $tablet-breakpoint) {
      width: 653px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      width: 960px;
      margin: 118px auto;
    }

    @media (min-width: $desktop-breakpoint) {
      width: 1320px;
      margin-bottom: 34px;
    }

    .list-header {
      @media (min-width: $big-tablet-breakpoint) {
        display: flex;
        justify-content: space-between;
      }

      .section-header {
        margin-bottom: 28px;

        @media (max-width: $tablet-breakpoint - 1) {
          margin-left: 20px;
        }

        @media (min-width: $big-tablet-breakpoint) {
          font-size: 36px !important;
          margin-bottom: 42px;
        }
      }
    }

    .movie-cards-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (min-width: $tablet-breakpoint) {
        justify-content: space-between;
      }

      @media (min-width: $big-tablet-breakpoint) {
        flex-direction: row;
      }
    }

    .call-to-action {
      font-family: Roboto, serif;
      font-weight: bold;
      font-size: 16px;
      color: #d92e26;

      @media (max-width: $tablet-breakpoint - 1) {
        margin-left: 20px;
      }

      @media (min-width: $big-tablet-breakpoint) {
        padding-top: 28px;
      }
    }
  }

  .in-theaters-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $big-tablet-breakpoint) {
      margin-top: 60px;
      flex-direction: row;
    }

    @media (min-width: $big-tablet-breakpoint) {
      max-width: $big-tablet-breakpoint;
    }

    @media (min-width: $desktop-breakpoint) {
      max-width: $desktop-breakpoint;
    }
    .main-column-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .sidebar-column-container {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 100%;

      @media (min-width: $big-tablet-breakpoint) {
        // truncates title
        .section-header {
          margin-right: 35px;
          margin-bottom: 40px;
          font-size: 36px !important;
          letter-spacing: 0.64px;
          line-height: 46px;
        }
        width: 304px;
        margin-right: $big-tablet-margin;
        margin-bottom: 101px;
      }

      @media (min-width: $desktop-breakpoint) {
        .section-header {
          margin-bottom: 58px;
        }
        width: 424px;
        margin-right: $desktop-margin;
        margin-bottom: 126px;
      }

      .weekly-box-office-container {
        margin-bottom: 0;
        .call-to-action {
          @media (min-width: $big-tablet-breakpoint) {
            margin-top: 41px;
          }
          @media (min-width: $desktop-breakpoint) {
            margin-top: 56px;
          }
        }
      }

      .in-theaters-box-office {
        height: 94px;

        @media (min-width: $big-tablet-breakpoint) {
          height: 172px;
        }

        .box-office-movie-detail {
          align-items: flex-end;
          padding: 20px 20px;

          .box-office-movie-rank {
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  .my-theaters-container {
    margin: auto;
    max-width: 1440px;

    @media screen and (min-width: $small-mobile-breakpoint) {
      position: relative;
    }

    @media screen and (min-width: $big-tablet-breakpoint) {
      padding-right: 3px;
    }

    .simple-date-selector-component-container {
      float: right;

      @media screen and (min-width: $small-mobile-breakpoint) and (max-width: $big-tablet-breakpoint - 1) {
        float: unset;
        margin-top: -74px;
        margin-left: 5%;
        position: relative;
        top: 137px;
        width: 100%;
      }

      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 5%;
      }

      @media screen and (min-width: $mobile-breakpoint + 1) and (max-width: $tablet-breakpoint - 1) {
        padding-left: 20px;
      }

      @media screen and (min-width: $tablet-breakpoint) and (max-width: $big-tablet-breakpoint) {
        margin-left: 4%;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        margin-right: 2px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        margin-right: 34px;
      }

      .simple-date-selector {
        .date-tiles-container.scroll-wrapper {
          .scroll::before {
            padding-left: 0;
          }
        }
      }
    }

    .favorite-theaters {
      @media screen and (min-width: $small-mobile-breakpoint) {
        padding-bottom: 60px;
      }

      @media screen and (min-width: $big-tablet-breakpoint) {
        padding-top: 26px;
      }

      .theater-showtimes-container {
        @media screen and (min-width: $small-mobile-breakpoint) and (max-width: $big-tablet-breakpoint - 1) {
          position: relative;
          top: 76px;
        }
      }

      .theater-accordion-container {
        @media screen and (min-width: $big-tablet-breakpoint) {
          width: 100%;
        }
      }
    }
  }
}
