@import 'variables';

// Opacity
// use-case: @include opacity(0.8);
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// Transition
// use-case: @include transition(background-color 1s .5s, color 2s);
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

// For main.scss

// Page container for when you need to wrap content
// that adhere to the page's breakpoint margins
// use-case: @include page-container()
@mixin page-container() {
  width: 100%;
  max-width: $mobile-breakpoint;
  padding: 0 $mobile-margin;
  margin: 0 auto;

  @media (min-width: $tablet-breakpoint) {
    max-width: $tablet-breakpoint;
    padding: 0 $tablet-margin;
  }

  @media (min-width: $big-tablet-breakpoint) {
    max-width: $big-tablet-breakpoint;
    padding: 0 $big-tablet-margin;
  }

  @media (min-width: $desktop-breakpoint) {
    max-width: $desktop-breakpoint;
    padding: 0 $desktop-margin;
  }
}

@mixin navbar-container() {
  width: 100%;
  padding: 0 $mobile-margin;
  margin: 0 auto;

  @media (min-width: $mobile-breakpoint) {
    max-width: $tablet-breakpoint - 1;
  }

  @media (min-width: $tablet-breakpoint) {
    max-width: $big-tablet-breakpoint - 1;
    padding: 0 $tablet-margin;
  }

  @media (min-width: $big-tablet-breakpoint) {
    max-width: $desktop-breakpoint - 1;
    padding: 0 $big-tablet-margin;
  }

  @media (min-width: $desktop-breakpoint) {
    max-width: $desktop-breakpoint;
    padding: 0 $desktop-margin;
  }
}

// Page spacing standardized between header and content
// use-case: @include page-margin-top()
@mixin page-margin-top() {
  margin-top: 12px;

  @media (min-width: $tablet-breakpoint) {
    margin-top: 26px;
  }

  @media (min-width: $desktop-breakpoint) {
    margin-top: 24px;
  }
}

// Responsive container to keep content aspect ratio maintained
// when sizing the browser up or down
// use-case: @include responsive-container(16, 9)
@mixin responsive-container($width, $height, $container-width: 100%) {
  $aspectRatio: $height / $width;

  position: relative;
  width: $container-width;
  height: 0;
  padding-top: $container-width * $aspectRatio;
  overflow: hidden;
}

@mixin non-responsive-container($width, $height) {
  position: relative;
  width: $width * 1px;
  height: 0;
  padding-top: $height * 1px;
  overflow: hidden;
}

// Fill with position absolute with padding-top to maintain aspect ratios
// (often used with @include responsive-container in parent)
// use-case: @include fill-container()
@mixin fill-container() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// Default background image properties with cover sizing
// use-case: @include background-image-container()
@mixin background-image-container() {
  background: $gray-color no-repeat center center;
  background-size: cover;
}

// Grid columns with auto placement of content ($max-width can be used for flex-wrap)
// Intended to be used with Semantic UI React Grid to offer more control
// https://react.semantic-ui.com/collections/grid/
// use-case: @include grid-column-auto-container(6, 12px, 14px)
@mixin grid-column-auto-container(
  $columns,
  $col-gap,
  $row-gap: $col-gap,
  $max-width: 100%
) {
  $horizontal: $col-gap/2;
  $vertical: $row-gap/2;

  &.ui.grid {
    margin: (-$vertical) (-$horizontal);

    .column:not(.row),
    .row > .column {
      width: 100% * (1 / $columns);
      padding: $vertical $horizontal;

      @if (unit($max-width) == '%') {
        max-width: $max-width;
      } @else {
        max-width: $max-width + $col-gap;
      }
    }
  }
}

// Flickity cell selector sizing and positioning
// Intended to be used for cell selectors within Flickity components
// https://flickity.metafizzy.co/options.html#cellselector
// design use-case: https://zpl.io/2Zr7KBJ
// use-case: @include list-row-cell-selector(6, 12px, $mobile-breakpoint)
@mixin flickity-cell-selector(
  $columns,
  $col-gap,
  $breakpoint-margin,
  $max-width: 100%
) {
  $page-margins: $breakpoint-margin * 2;
  $col-margins: $col-gap * ($columns - 1);

  width: calc((100% - #{$page-margins} - #{$col-margins}) / #{$columns});
  max-width: $max-width;
  margin: 0 ($col-gap/2);

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

// Flickity prev and next button centered positioning with bottom content
// Intended to be used for prev and next buttons within Flickity components
// https://flickity.metafizzy.co/style.html#previous-next-buttons
// design use-case: https://zpl.io/2Zr7KBJ
// use-case: @include list-row-cell-centered-buttons(40px, 8px)
@mixin flickity-cell-centered-buttons($bottom-height, $bottom-margin: 0) {
  $bottom-container: $bottom-height + $bottom-margin;

  .flickity-prev-next-button {
    top: calc((100% - #{$bottom-container}) / 2);
  }
}

// Flickity prev and next button centered positioning with bottom content
// based on primary content aspect ratio
// use-case: @include flickity-cell-content-centered-buttons(120, 180)
@mixin flickity-cell-content-centered-buttons($content-width, $content-height) {
  $aspect-ratio: $content-width / $content-height;

  .flickity-prev-next-button {
    top: calc((100% * #{$aspect-ratio}) / 2);
  }
}
