@import './variables';
@import './mixins';

.in-theaters {
  .poster-rows-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    min-height: 434px;
    background: black;

    @media (min-width: $mobile-breakpoint) {
      min-height: 404px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      min-height: 550px;
    }

    @media (min-width: $desktop-breakpoint) {
      min-height: 504px;
    }

    .poster-row-tabs-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      @media (min-width: $big-tablet-breakpoint) {
        margin-bottom: 39px;
      }

      .poster-row-tabs {
        display: flex;
        align-items: center;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.25px;
        color: white;
        margin: 0;

        @media (min-width: $big-tablet-breakpoint) {
          font-size: 24px;
          line-height: 1.5;
          letter-spacing: 0.43px;
        }

        .poster-row-tab {
          padding-bottom: 8px;
          margin-right: 19px;
          cursor: pointer;

          @media (min-width: $tablet-breakpoint) {
            margin-right: 40px;
          }

          @media (min-width: $big-tablet-breakpoint) {
            margin-right: 60px;
          }

          @media (min-width: $desktop-breakpoint) {
            margin-right: 100px;
          }

          &:last-child {
            margin-right: 0;
          }

          &.active {
            border-bottom: 2px solid white;
          }
        }
      }
    }

    .poster-row-movies {
      .poster-movie-cell-selector {
        max-width: 120px;
        margin: 0 5px;

        &:first-of-type {
          margin-left: 0;
        }

        @media (min-width: $big-tablet-breakpoint) {
          max-width: 180px;
          margin: 0 7.5px;
        }

        @media (min-width: $desktop-breakpoint) {
          max-width: 200px;
        }

        .poster-title {
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0.28px;
          color: white;

          @media (min-width: $tablet-breakpoint) {
            font-size: 16px;
          }
        }

        .poster-meta {
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0.25px;
          color: white;
        }
      }
    }

    .call-to-action-container {
      display: inline-block;

      @media (max-width: $desktop-breakpoint - 1) {
        padding-top: 50px;
        padding-bottom: 40px;
      }

      @media (min-width: $desktop-breakpoint) {
        margin-right: -6px;
      }

      .call-to-action {
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.25px;
        color: white;

        @media (min-width: $tablet-breakpoint) {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: 0.29px;
        }

        @media (min-width: $big-tablet-breakpoint) {
          font-size: 18px;
          line-height: 2;
          letter-spacing: 0.32px;
        }

        @media (min-width: $desktop-breakpoint) {
          padding-bottom: 8px;
        }
      }
    }

    // Flickity
    .flickity-wrapper {
      .flickity-viewport {
        .flickity-slider {
          &:first-child {
            margin-left: 0;
          }
        }
      }

      .flickity-prev-next-button {
        top: calc((100% / 2) - 42px);

        @media (max-width: $desktop-breakpoint - 1) {
          &.previous {
            left: -25px;
          }

          &.next {
            right: -25px;
          }

          &.previous::before,
          &.next::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            display: block;
            width: 60px;
            height: 60px;
            background: black;
            opacity: 0.5;
            border-radius: 50%;
          }
        }

        .flickity-button-icon {
          .arrow {
            fill: white;
          }
        }

        &:disabled {
          opacity: 1;

          .flickity-button-icon {
            .arrow {
              fill: rgba(255, 255, 255, 0.3);
            }
          }
        }
      }
    }

    .scroll-wrapper {
      .scroll {
        &::before {
          padding: 0;
        }
      }
    }
  }
}
