@import 'variables';
@import 'mixins';

.medium-card {
  width: 100%;
  margin-bottom: 60px;

  @media (min-width: $big-tablet-breakpoint) {
    margin-bottom: 76px;
  }

  .medium-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .medium-card-image-container {
      width: 182px;

      @media (min-width: $tablet-breakpoint) {
        width: 304px;
      }

      @media (min-width: $big-tablet-breakpoint) {
        width: 304px;
      }

      @media (min-width: $desktop-breakpoint) {
        width: 424px;
      }

      &.left-aligned {
        margin-right: 11px;

        @media (min-width: $tablet-breakpoint) {
          margin-right: 16px;
        }

        @media (min-width: $big-tablet-breakpoint) {
          margin-right: 24px;
        }
      }

      &.right-aligned {
        margin-left: 11px;

        @media (min-width: $tablet-breakpoint) {
          margin-left: 16px;
        }

        @media (min-width: $big-tablet-breakpoint) {
          margin-left: 24px;
        }
      }

      .medium-card-image {
        @include background-image-container();
        @include non-responsive-container(182, 116);

        @media (min-width: $tablet-breakpoint) {
          @include non-responsive-container(304, 160);
        }

        @media (min-width: $big-tablet-breakpoint) {
          @include non-responsive-container(304, 160);
        }

        @media (min-width: $desktop-breakpoint) {
          @include non-responsive-container(424, 222);
        }
      }
    }

    .medium-card-details-container {
      flex: 1;

      &.left-aligned {
        padding-right: $mobile-margin;

        @media (min-width: $tablet-breakpoint) {
          padding: 0;
        }
      }

      &.right-aligned {
        padding-left: $mobile-margin;

        @media (min-width: $tablet-breakpoint) {
          padding: 0;
        }
      }

      .medium-card-published-date {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.25px;
        color: #595859;
        margin-bottom: 4px;

        @media (min-width: $tablet-breakpoint) {
          line-height: 1.43;
          margin-bottom: 8px;
        }

        @media (min-width: $tablet-breakpoint) {
          line-height: normal;
        }

        @media (min-width: $big-tablet-breakpoint) {
          line-height: 1.43;
        }

        @media (min-width: $desktop-breakpoint) {
          line-height: normal;
        }
      }

      .medium-card-name {
        font-family: Oswald;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
        //letter-spacing: 0.38px;
        color: #000000;

        @media (min-width: $tablet-breakpoint) {
          font-size: 24px;
          line-height: 1.25;
          // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
          //letter-spacing: 0.5px;
          margin-bottom: 12px;
        }

        @media (min-width: $big-tablet-breakpoint) {
          // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
          //letter-spacing: 0.33px;
        }

        @media (min-width: $desktop-breakpoint) {
          font-size: 36px;
          line-height: 1.11;
          // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
          //letter-spacing: 0.5px;
          margin-bottom: 16px;
        }
      }

      .medium-card-description {
        display: none;

        @media (min-width: $tablet-breakpoint) {
          display: flex;
          font-family: Roboto;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.38;
          letter-spacing: 0.29px;
          color: #595859;
        }
      }
    }
  }

  .medium-card-mobile-description-container {
    padding: $mobile-margin;

    .medium-card-mobile-description {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
      //letter-spacing: 0.29px;
      color: #595859;

      @media (min-width: $tablet-breakpoint) {
        display: none;
      }
    }
  }
}

.large-card,
.streaming-card {
  width: 100%;
  margin-bottom: 60px;

  @media (min-width: $big-tablet-breakpoint) {
    margin-bottom: 76px;
  }

  .large-card-image-container,
  .streaming-card-image-container {
    @include background-image-container();
    @include responsive-container(375, 210);

    @media (min-width: $mobile-breakpoint) {
      @include non-responsive-container(375, 210);
    }

    @media (min-width: $tablet-breakpoint) {
      @include non-responsive-container(668, 286);
    }

    @media (min-width: $big-tablet-breakpoint) {
      @include non-responsive-container(632, 356);
    }

    @media (min-width: $desktop-breakpoint) {
      @include non-responsive-container(760, 428);
    }
  }

  // Streaming cards
  .streaming-card-image-container {
    @include background-image-container();
    @include responsive-container(375, 210);

    @media (min-width: $tablet-breakpoint) {
      @include responsive-container(668, 286);
    }

    @media (min-width: $big-tablet-breakpoint) {
      @include responsive-container(760, 428);
    }
  }



  .large-card-details-container,
  .streaming-card-details-container,
  .small-streaming-card-details-container {
    padding: 0 $mobile-margin;

    @media (min-width: $tablet-breakpoint) {
      padding: 0;
    }

    .large-card-published-date,
    .streaming-card-advisory-genre {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.25px;
      color: #595859;
      margin-top: 20px;
      margin-bottom: 6px;

      @media (min-width: $tablet-breakpoint) {
        margin-bottom: 8px;
      }
    }

    .large-card-title,
    .streaming-card-title {
      font-family: Oswald;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: 0.5px;
      color: #000000;
      margin-bottom: 12px;

      @media (min-width: $tablet-breakpoint) {
        font-size: 36px;
        line-height: 1.28;
        letter-spacing: 0.75px;
        margin-bottom: 16px;
      }
    }

    .large-card-description,
    .streaming-card-description {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      color: #595859;
    }

    .streaming-card-description {
      letter-spacing: 0.29px;
    }
  }
}

.small-streaming-card {
  .small-streaming-card-image-container {
    @include responsive-container(16, 9);
    @include background-image-container();
  }

  .small-streaming-card-title {
    font-family: Oswald, serif;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
    color: #000000;
  }

  .small-streaming-card-rating-genre {
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    color: #595859;

    @media (min-width: $tablet-breakpoint) {
    }
  }
}

.small-card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  @media (min-width: $big-tablet-breakpoint) {
    margin-bottom: 76px;
  }

  .small-card {
    width: 49%;

    @media (min-width: $mobile-breakpoint) {
      width: 182px;
    }

    @media (min-width: $tablet-breakpoint) {
      width: 326px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      width: 304px;
    }

    @media (min-width: $desktop-breakpoint) {
      width: 368px;
    }

    .small-card-image-container {
      @include background-image-container();
      @include responsive-container(182, 116);

      @media (min-width: $mobile-breakpoint) {
        @include non-responsive-container(182, 116);
      }

      @media (min-width: $tablet-breakpoint) {
        @include non-responsive-container(326, 208);
      }

      @media (min-width: $big-tablet-breakpoint) {
        @include non-responsive-container(304, 160);
      }

      @media (min-width: $desktop-breakpoint) {
        @include non-responsive-container(368, 206);
      }
    }

    &:first-child {
      .small-card-details-container {
        padding-left: $mobile-margin;

        @media (min-width: $tablet-breakpoint) {
          padding: 0;
        }
      }
    }

    &:last-child {
      .small-card-details-container {
        padding-right: $mobile-margin;

        @media (min-width: $tablet-breakpoint) {
          padding: 0;
        }
      }
    }

    .small-card-details-container {
      .small-card-published-date {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.25px;
        color: #595859;
        margin-top: 20px;
        margin-bottom: 6px;
      }

      .small-card-title {
        font-family: Oswald;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
        //letter-spacing: 0.18px;
        color: #000000;
        margin-bottom: 14px;

        @media (min-width: $tablet-breakpoint) {
          font-size: 24px;
          line-height: 1.25;
          // Commented out because the letter-spacing property causes the react-truncate to not function correctly.
          //letter-spacing: 0.24px;
          margin-bottom: 12px;
        }
      }

      .small-card-description {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: 0.29px;
        color: #595859;
      }
    }
  }
}

.card-label-container {
  position: absolute;
  top: 16px;
  right: 0;
  pointer-events: none;
  overflow: hidden;
  width: 100%;
  height: 24px;

  @media (min-width: $tablet-breakpoint) {
    top: 30px;
    height: 34px;
  }

  .card-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(253deg, #f55f23, #e44129 49%, #d92e26);
    height: 100%;
    position: absolute;
    right: -10px;
    padding: 0 18px;
    transform: skewX(-35deg);

    @media (min-width: $tablet-breakpoint) {
      right: -14px;
      padding: 0 26px;
    }

    p {
      transform: skewX(35deg);
      color: $white-color;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.56px;
      text-transform: uppercase;

      @media (min-width: $tablet-breakpoint) {
        font-size: 16px;
        letter-spacing: 1.78px;
      }
    }
  }
}
