@import 'variables';
@import 'mixins';

.home-slideshow {
  @include background-image-container();
  height: 590px;

  @media (min-width: $tablet-breakpoint) {
    height: 488px;
  }

  @media (min-width: $big-tablet-breakpoint) {
    height: 520px;
  }

  @media (min-width: $desktop-breakpoint) {
    height: 648px;
  }

  .bg-cover {
    @include background-image-container();
    position: relative;
    width: 100%;
    height: 590px;
    overflow: hidden;

    @media (min-width: $tablet-breakpoint) {
      height: 488px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      height: 520px;
    }

    @media (min-width: $desktop-breakpoint) {
      height: 648px;
    }

    .bg-overlay {
      @include fill-container();
      background-color: rgba(0, 0, 0, 0.25);

      .carousel-slider-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        padding-top: 70px;

        .slider-content {
          @include page-container();
        }
      }
    }

    .bg-video-container {
      @include fill-container();

      video {
        position: relative;
        width: 100vw;
      }
    }
  }

  .title {
    font-family: Oswald;
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: 0.6px;
    color: #ffffff;
    width: 270px;

    @media (min-width: $tablet-breakpoint) {
      width: 326px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      width: 536px;
    }
  }

  .subtitle {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 2px;
    color: #ffffff;
  }

  .description {
    font-size: 16px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: 0.29px;
    color: #ffffff;
    width: 325px;
    height: 88px;
  }

  .call-to-action {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: 0.29px;
    color: #ffffff;
    display: flex;
    margin-top: 32px;
    align-items: center;

    svg {
      margin-left: 8px;
      height: 18px;
    }
  }

  .play-icon .icon {
    height: 70px;
    width: 70px;
  }

  @media (min-width: $big-tablet-breakpoint) {
    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100px;
      width: 100px;
      margin-left: -50px;
      margin-top: -50px;
    }

    .play-icon .icon {
      height: 100px;
      width: 100px;
    }
  }

  .slick-dots li button:before {
    font-size: 19px;
  }

  .slick-dots {
    bottom: 36px + 5px;

    @media (min-width: $tablet-breakpoint) {
      bottom: 32px + 5px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      bottom: 24px + 5px;
    }

    @media (min-width: $desktop-breakpoint) {
      bottom: 42px + 5px;
    }
  }

  .slick-dots li.slick-active button:before {
    color: #ffff;
  }

  .slick-dots li button:before {
    color: #ffff;
  }

  // Fix for bug in Slick-Slider dots
  // https://github.com/akiran/react-slick/issues/1432:
  .slick-dots li button:before {
    opacity: 0.25;
  }

  .slick-dots li.slick-active button:before,
  .slick-dots li button:hover:before {
    opacity: 1;
  }
}

// CONTINUED: Fix for bug in Slick-Slider dots
// Removes sticky hover on mobile
.home-slideshow-mobile {
  .slick-dots li.slick-active button:hover:before {
    opacity: 1;
  }

  .slick-dots li button:hover:before {
    opacity: 0.25;
  }
}

.home-content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 38px;

  @media (min-width: $tablet-breakpoint) {
    margin-top: 40px;
  }

  @media (min-width: $big-tablet-breakpoint) {
    margin-top: 90px;
  }

  @media (min-width: $desktop-breakpoint) {
    margin-left: $desktop-margin;
  }

  &.middle, &.bottom {
    margin-top: 0;
  }

  .main-column-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (min-width: $desktop-breakpoint) {
      max-width: 984px;
    }

    .search-and-show-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0 $mobile-margin;
      margin-bottom: 0;

      @media (min-width: $tablet-breakpoint) {
        padding: 0 $tablet-margin;
      }

      @media (min-width: $big-tablet-breakpoint) {
        padding: 0 $big-tablet-margin;
        margin-bottom: 76px;
      }

      @media (min-width: $desktop-breakpoint) {
        padding: 0;
      }
    }

    .show-container {
      width: 100%;
      margin-bottom: 76px;
    }
  }

  .sidebar-column-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    padding: 0 $mobile-margin;

    @media (min-width: $tablet-breakpoint) {
      padding: 0 $tablet-margin;
    }

    @media (min-width: $big-tablet-breakpoint) {
      width: 304px;
      padding: 0;
      margin-left: 24px;
      margin-right: $big-tablet-margin;
    }

    @media (min-width: $desktop-breakpoint) {
      width: 312px;
      margin-right: $desktop-margin;
    }
  }
}

.home-content-fixed-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .home-content-container {
    width: 100%;

    @media (min-width: $mobile-breakpoint) {
      width: 375px;
    }

    @media (min-width: $tablet-breakpoint) {
      width: 668px;
    }

    @media (min-width: $big-tablet-breakpoint) {
      width: 960px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 1020px;
      }
    }
  }
}

.home-search {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (min-width: $big-tablet-breakpoint) {
    max-width: 808px;
  }

  .home-search-input-icon {
    position: absolute;
    top: 10px;
    @media (min-width: $tablet-breakpoint) {
      display: none;
    }
  }

  .home-search-input-container {
    flex: 1;
    position: relative;

    input {
      width: 100%;
      flex: 1;
      height: 46px;
      border-top: transparent;
      border-left: transparent;
      border-right: transparent;
      border-bottom: solid 2px rgba(221, 221, 221, 0.47);
      outline: none;
      background-color: #ffffff;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: 0.29px;
      color: $black-absolute-color;
      padding-left: 30px;

      @media (min-width: $tablet-breakpoint) {
        height: 56px;
        padding-left: 20px;
        border: solid 1px rgba(183, 183, 183, 0.47);
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .find-showtimes {
    background-image: linear-gradient(258deg, #f55f23, #e44129 49%, #d92e26);
    border: none;
    font-size: 18px;
    font-weight: lighter;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: 20px;
    padding: 17px 15px;
    width: 198px;
    cursor: pointer;
    height: 56px;

    @media (max-width: $tablet-breakpoint - 1) {
      font-size: 16px;
      height: 45px;
      width: 57px;
      padding: 0;
      margin: 0 0 0 20px;
    }
  }
}

.suggestion-container {
  z-index: 100;
  position: absolute;
  top: 56px;
  width: 100%;
  height: 288px;
  border: solid 1px #dddddd;
  background-color: $white;

  font-size: 16px;
  line-height: 2.5;
  letter-spacing: 0.29px;
  padding-top: 0;

  .current-location {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;

    margin-left: 4px;

    div {
      margin-left: -4px;
    }
  }

  .suggestion {
    height: 35px;
    margin-bottom: 10px;
    margin-left: 16px;
    cursor: pointer;
  }
}

.content-feed-divider {
  width: 100%;
  border: solid 2px #dddddd;
  margin-top: 0;
  margin-bottom: 50px;
}

.content-feed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (min-width: $big-tablet-breakpoint) {
    padding-left: $big-tablet-margin;
    padding-right: 0;
    margin-top: 0;
  }

  @media (min-width: $desktop-breakpoint) {
    padding: 0;
  }

  .content-feed {
    width: 100%;

    @media (min-width: $desktop-breakpoint) {
      max-width: 760px;
    }
  }
}

.content-feed-more-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: $mobile-margin;
  margin-top: 20px;
  margin-bottom: 120px;

  @media (min-width: $tablet-breakpoint) {
    padding-right: 0;
  }

  @media (min-width: $big-tablet-breakpoint) {
    margin-top: 14px;
  }

  @media (min-width: $desktop-breakpoint) {
    max-width: 760px;
  }

  .content-feed-more-line {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: $black-absolute-color;

    &::before {
      content: "";
      position: absolute;
      right: 100%;
      width: inherit;
      height: inherit;
      background-color: inherit;
    }
  }

  .content-feed-more-text {
    background: transparent;
    border: transparent;
    display: flex;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.18;
    letter-spacing: 2px;
    color: $black-absolute-color;
    text-transform: uppercase;
    margin-left: 20px;
    cursor: pointer;

    svg {
      margin-left: 8px;
      -webkit-tap-highlight-color: transparent;
    }
  }
}
