$responsive-desktop: 1280px;
$responsive-big-tablet: 1279px;
$responsive-tablet: 991px;
$responsive-mobile: 719px;

$inner-desktop: 1280px;
$inner-big-tablet: 992px;
$inner-tablet: 720px;
$inner-mobile: 375px;

$red: #E32526;
$orange: #FD6420;
$blue: #4a90e2;
$white: #FFFFFF;
$off-white: #f7f7f7;
$black: #222222;
$dark-gray: #4a4a4a;
$light-gray: #888888;
$gray: #4a4a4a;
$purple: #595859;
$purplish-gray: #595859;
$copper: #d92e26;


// For main.scss
// will eventually replace all variables above

// Breakpoints
$small-mobile-breakpoint: 320px;
$mobile-breakpoint: 375px;
$tablet-breakpoint: 720px;
$big-tablet-breakpoint: 1024px;
$desktop-breakpoint: 1440px;

// Margins
$small-mobile-margin: 10px;
$mobile-margin: 20px;
$tablet-margin: 26px;
$big-tablet-margin: 32px;
$desktop-margin: 60px;

// Margins (Auto)
$mobile-margin-auto: calc((100% - #{$mobile-breakpoint}) / 2);
$tablet-margin-auto: calc((100% - #{$tablet-breakpoint}) / 2);
$big-tablet-margin-auto: calc((100% - #{$big-tablet-breakpoint}) / 2);
$desktop-margin-auto: calc((100% - #{$desktop-breakpoint}) / 2);

// Colors
$example-color: plum;
$red-color: #e32526;
$orange-color: #fd6420;
$white-color: #ffffff;
$white-gray-color: #f0f0f0;
$gray-color: #cccccc;
$gray-mid-color: #969696;
$gray-dark-color: #4a4a4a;
$charcoal-color: #2f2e31;
$black-color: #222222;
$black-absolute-color: #000000;

// Header Height
$header-height-mobile: 76px;
$header-height-default: 96px;
